import React, { useEffect, useState } from "react";
import {
  Typography, Avatar, Grid, Stack, CircularProgress, Button, Dialog, DialogTitle, DialogContent,
  FormControl, MenuItem, InputLabel, Select, DialogActions
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import {
  ResponsiveContainer, PieChart, Pie, Cell, Tooltip, BarChart, XAxis, YAxis, Bar,
} from "recharts";
import OpContrattTab from "./OpContrattTab";

const COLORS = [
  "#0088a8", // Blu Turchese
  "#118080", // Turchese
  "#035974", // Blu Petrolio
  "#325167", // Blu Verde
  "#354360", // Blu Acciaio
  "#0b3347", // Blu Notte
  "#36402e", // Verde Foresta Scuro
  "#4f3e2b", // Marrone Terra
  "#2a4d55", // Blu Verde Scuro
  "#2f3e46", // Grigio Blu
  "#6a6a6a", // Grigio Medio
];

const pratiche = [
  "assunzione",
  "cessazione",
  "proroga",
  "trasformazione",
  "chiamata intermittente",
  "infortunio",
  "contestazione",
  "distacco",
  "distacco estero",
  "pratica generica",
];

const PRATICHE = [
  "Assunzioni",
  "Cessazioni",
  "Proroghe",
  "Trasformazioni",
  "Chiamate Intermittenti",
  "Infortuni",
  "Contestazioni",
  "Distacchi",
  "Distacchi Esteri",
  "Pratiche Generiche",
  "Annullamenti",
];

const urgentColors = ["#FF5733", "#5cb85c"];

const OpContrattualistica = () => {
  const [isHovered, setIsHovered] = useState(new Array(PRATICHE.length).fill(false));
  const [username, setUsername] = useState("");
  const [completedPracticheData, setCompletedPratcheData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedPratica, setSelectedPratica] = useState('assunzione');

  useEffect(() => {
    loadData();
    loadCharts();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && !me.op) {
          window.location.href = "/cli";
          return;
        }
        if (success) {
          setUserId(me.id); // Aggiungi questa riga per impostare l'ID dell'utente
          setUsername(`${me.nome.charAt(0).toUpperCase()}${me.nome.substring(1).toLowerCase()} ${me.cognome.charAt(0).toUpperCase()}.`);
          return;
        }
      }
    } catch (_) { }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadCharts = async () => {
    try {
      const res = await axios.get(`/api/ticketstats`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setCompletedPratcheData(res.data.stats.bars);
        setChartData([
          {
            dataKey: "value",
            data: [
              { name: "Da Fare", value: res.data.stats.urgent.tot - res.data.stats.urgent.done },
              // { name: "Fatte", value: res.data.stats.urgent.done },
            ],
            title: "Urgenti",
          },
          {
            dataKey: "value",
            data: PRATICHE.map(s => { return { name: s, value: res.data.stats.tot[s.toLowerCase()] } }),
            title: "Totale Da Fare",
            linkNames: PRATICHE.map(p => p.toLowerCase()),
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  }

  const [userId, setUserId] = useState(0);
  const avatarUrl = `/api/avatar?id=${userId}`;

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="flex-start"
        spacing={0}
        style={{ marginTop: "10px", marginBottom: "10px" }}
      >
        <Grid
          item
          xs={10}
          md={2}
          style={{
            background: "linear-gradient(to bottom, #1e1e1e, #353535)",
            padding: "20px",
            borderRadius: "2vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
          }}
        >
          <Grid container alignItems="center" style={{ paddingBottom: "20px" }}>
            <Grid item>
              <Avatar
                alt={username}
                src={avatarUrl}
                style={{
                  width: "50px",
                  height: "50px",
                  marginRight: "10px",
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6" color="white">
                {username}
              </Typography>
            </Grid>
          </Grid>
          {PRATICHE.map((title, index) => (
            <Link
              key={title}
              to={`/op/${title.toLowerCase().replace(' ', '')}`}
              style={{
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                width: "100%",
                padding: "8px 0",
                borderRadius: "4px",
                color: "white",
                transition: "transform 0.2s, filter 0.2s, box-shadow 0.2s",
                boxShadow: "10px 10px 10px rgba(10, 10, 0, 0.5)",
                marginBottom: "10px",
                backgroundColor: isHovered[index] ? COLORS[index % COLORS.length] : "#333",
                transform: isHovered[index] ? "scale(1.05)" : "scale(1)",
                filter: isHovered[index] ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() =>
                setIsHovered((prev) => prev.map((_, i) => i === index))
              }
              onMouseLeave={() =>
                setIsHovered((prev) => prev.map((_, i) => false))
              }
            >
              {title}
            </Link>
          ))}

          <Button
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "8px 0",
              borderRadius: "4px",
              color: "black",
              transition: "transform 0.2s, filter 0.2s, box-shadow 0.2s",
              boxShadow: "10px 10px 10px rgba(10, 10, 0, 0.5)",
              marginTop: "20px",
              marginBottom: "10px",
              backgroundColor: "#cccccc"
            }}
            onClick={() => setDialogOpen(true)}
          >
            Crea Pratica
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={9}
          container
          spacing={0}
          justifyContent="center"
          alignItems="center"
          style={{
            margin: "30px",
          }}
        >
          {chartData.map((chart) => (
            <Grid item xs={12} md={3} key={chart.title}>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart>
                  <Pie
                    dataKey={chart.dataKey}
                    isAnimationActive={true}
                    data={chart.data}
                    cx="50%"
                    cy="50%"
                    innerRadius={20}
                    outerRadius={60}
                    paddingAngle={5}
                    cornerRadius={5}
                    fill="#8884d8"
                    label
                  >
                    {chart.data.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          chart.data[index].value === 0
                            ? "#ccc"
                            : chart.title === "Urgenti"
                              ? urgentColors[index % urgentColors.length]
                              : COLORS[index % COLORS.length]
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  {/* <Legend /> */}
                </PieChart>
              </ResponsiveContainer>
              <Typography
                variant="subtitle1"
                color="black"
                style={{ textAlign: "center" }}
              >
                {chart.title}
              </Typography>
            </Grid>
          ))}

          <Grid item xs={12} md={6}>
            {isLoading ? (
              <Stack sx={{ margin: 2 }} alignItems="center">
                <CircularProgress disableShrink />
              </Stack>
            ) : (<div>
              <ResponsiveContainer width="100%" height={250}>
                <BarChart data={completedPracticheData}>
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  {PRATICHE.map(p => (
                    <Bar
                      dataKey={p.toLowerCase()}
                      stackId="a"
                      fill={COLORS[PRATICHE.indexOf(p)]}
                    // shape={(props) => (
                    //   <rect
                    //     {...props}
                    //     rx={5} // Imposta il raggio per ottenere i bordi arrotondati
                    //     ry={5}
                    //   />
                    // )}
                    >
                      {/* <LabelList dataKey="completed" position="top" /> */}
                    </Bar>))}
                </BarChart>
              </ResponsiveContainer>
              <Typography
                variant="subtitle1"
                color="black"
                style={{ textAlign: "center" }}
              >
                Pratiche Completate
              </Typography>
            </div>)}
          </Grid>

          <Grid item xs={12} md={12} style={{ marginTop: "30px" }}>
            <OpContrattTab dafare={true} />
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Crea una nuova pratica</DialogTitle>
        <DialogContent>
          <Select
            labelId="pratica-label"
            id="pratica"
            name="pratica"
            value={selectedPratica}
            onChange={e => setSelectedPratica(e.target.value)}
            required
            style={{ width: '100%' }}
          >
            {pratiche.map((p) => (
              <MenuItem key={p} value={p}>{p.toUpperCase()}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Chiudi
          </Button>
          <Button
            onClick={() => window.location.href = `/op/contrattualistica/${selectedPratica.replace(' ', '')}`}
            color="primary"
            autoFocus
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};

export default OpContrattualistica;
