module.exports = {
  "items": [
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (IV trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250598,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (IV trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250597,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-01-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MODELLO 730 – ASSISTENZA FISCALE PRESTATA DAL SOSTITUTO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">il sostituto d'imposta comunica se intende prestare assistenza fiscale diretta</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250599,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-01-15T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento seconda rata acconti IRPEF, IVIE, IVAFE, cedolare secca, imposta sostitutiva minimi/forfetari (prima rata o unica soluzione) per le persone fisiche titolari di partita IVA con ricavi/compensi fino a € 170.000.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5099096,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di dicembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250601,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di dicembre 2024:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS: committenti</li></ul></li>\r\n  <li>versamento all'INPS dei contributi per i coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al IV trimestre 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250602,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di dicembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250603,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d'appalto nei confronti dei condomini nonché sull'ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (dicembre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250604,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE CONDOMINIO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">\r\n  <span style=\"font-size: 1rem;\">versamento ritenute operate nel mese di dicembre 2024 da parte del condominio nel caso non sia superato il limite di euro 500 di ritenute operate</span>\r\n</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5251497,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-01-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (IV trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250605,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-01-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (dicembre 2024) e trimestrali (IV trimestre 2024)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250606,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-01-27T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FATTURE ELETTRONICHE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">\r\n  <span style=\"color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial;\">termine ultimo per la conservazione digitale delle fatture elettroniche 2023.</span>\r\n  <span style=\"font-size: 1rem;\"> </span>\r\n  <span style=\"font-size: 1rem;\"> </span>\r\n</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5099963,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di dicembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250608,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COLLOCAMENTO OBBLIGATORIO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio del prospetto informativo</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250609,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di dicembre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250610,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all'Agenzia delle Dogane dell'istanza relativa al IV trimestre 2024 per il rimborso/compensazione del maggior onere derivante dall'incremento dell'accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250611,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine entro cui effettuare le modifiche all'Elenco B predisposto dall'Agenzia delle entrate e relativo alle fatture del IV trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250612,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250613,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI AL SISTEMA TS</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Trasmissione dei documenti di spesa pagati nel periodo 1.7 - 31.12.2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250614,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONSERVAZIONE DIGITALE DICHIARAZIONI FISCALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Termine entro cui effettuare la conservazione digitale delle dichiarazioni fiscali relative al 2022 ai sensi del D.M. 17/6/2014.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250634,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">REGISTRI CONTABILI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">stampa o conservazione digitale del libro giornale, mastro, inventari, registri IVA e registro dei beni ammortizzabili relativi al 2023; in alternativa, è possibile stampare i registri solo a seguito della richiesta avanzata in sede di controllo (art. 7, comma 4-quater, D.L. n. 357/1994).</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250644,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-01-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">BONUS PUBBLICITÀ</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione dichiarazione sostitutiva relativa agli investimenti effettuati nell'anno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250616,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-02-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento premio (regolarizzazione 2024 e anticipo 2025) o I rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250623,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento seconda rata acconti IRPEF, IVIE, IVAFE, cedolare secca, imposta sostitutiva minimi/forfetari (seconda rata) per le persone fisiche titolari di partita IVA con ricavi/compensi fino a € 170.000.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5272053,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TFR - IMPOSTA SOSTITUTIVA SULLA RIVALUTAZIONE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento a saldo sulle rivalutazioni del TFR maturate nell’anno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250624,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di gennaio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250625,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di gennaio 2025:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250627,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (gennaio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250618,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (gennaio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250617,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di gennaio 2025 e IV trimestre 2024 (trimestrali speciali)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250621,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento IV quota fissa 2024 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250622,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-02-17T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi IV trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250628,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-02-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (gennaio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250629,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-02-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250643,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COMUNICAZIONE/ADESIONE O REVOCA DEL REGIME AGEVOLATO CONTRIBUTIVO FORFETARI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">al fine di fruire del regime agevolato contributivo nel 2025, o di revocare lo stesso per fuoriuscita 2025, i contribuenti forfetari trasmettono telematicamente all’INPS l’apposita domanda</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250631,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il IV trimestre 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250637,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al IV trimestre 2024 (detta comunicazione può essere compresa nella Dichiarazione annuale IVA da presentare entro il mese di febbraio)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250638,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS – ex DMAG) di gennaio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250639,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (I trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250640,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di gennaio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250642,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica delle dichiarazioni delle retribuzioni 2024 con eventuale domanda di riduzione del tasso medio di tariffa</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250641,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-02-28T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di febbraio 2025:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per la manodopera agricola relativi al III trimestre 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250659,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento seconda rata acconti IRPEF, IVIE, IVAFE, cedolare secca, imposta sostitutiva minimi/forfetari (terza rata) per le persone fisiche titolari di partita IVA con ricavi/compensi fino a € 170.000.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5272054,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TASSA ANNUALE LIBRI CONTABILI E SOCIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento da parte delle società di capitali</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250651,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CERTIFICAZIONI UTILI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">consegna ai percettori delle certificazioni relative agli utili corrisposti nel 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250652,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI RELATIVI AD ONERI DA PARTE DI SOGGETTI TERZI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio all’Agenzia delle Entrate dei dati relativi a oneri deducibili/detraibili sostenuti nell’anno precedente da ciascun contribuente da parte di: istituti bancari, assicurazioni, agenzie funebri, università, asili nido pubblici e privati e amministratori di condomini (in relazione a parti comuni di edifici residenziali) ecc.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250653,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">OPZIONE CESSIONE CREDITO/SCONTO IN FATTURA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio all’Agenzia delle Entrate della comunicazione di cessione del credito/sconto in fattura relativa alle spese sostenute nel periodo d’imposta precedente per gli interventi edilizi agevolati.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250673,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI RELATIVI AD EROGAZIONI LIBERALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">ONLUS, APS, fondazioni/associazioni riconosciute aventi per scopo statutario la tutela, promozione e la valorizzazione dei beni di interesse artistico, storico e paesaggistico, e fondazioni/associazioni riconosciute aventi per scopo statutario la tutela, promozione e la valorizzazione dei beni di interesse artistico, storico e paesaggistico inviano telematicamente, in via facoltativa, all’Agenzia delle Entrate una comunicazione contenente i dati relativi alle erogazioni liberali in denaro deducibili e detraibili eseguite nell’anno precedente da persone fisiche. L’invio è obbligatorio se risultano ricavi, rendite, proventi o entrate comunque denominate superiori a 220.000 euro</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250654,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI SPESE VETERINARIE AL SISTEMA TS</p><p class=\"Testo-scadenza\" lang=\"it-IT\">i veterinari trasmettono telematicamente al sistema Tessera Sanitaria i dati relativi alle spese veterinarie sostenute nel 2024 </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250646,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di febbraio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250658,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (febbraio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250647,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (febbraio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250649,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONGUAGLIO SOSTITUTI D’IMPOSTA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento delle ritenute alla fonte inerenti le operazioni di conguaglio eseguite nel corso del mese di febbraio 2025 da parte dei datori di lavoro e committenti sui redditi corrisposti nel corso dell’anno solare 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250650,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INVIO CU 2025 ORDINARIA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine per l’invio telematico all’Agenzia delle Entrate della CU 2025 ordinaria, nonché degli ulteriori dati fiscali e contributivi, dei dati necessari per l’attività di controllo dell’Agenzia delle Entrate e degli enti previdenziali e assicurativi, dei dati contenuti nelle certificazioni rilasciate ai soli fini contributivi e assicurativi e delle informazioni relative alle operazioni di conguaglio effettuate a seguito dell’assistenza fiscale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250655,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><ul>\r\n  <li>liquidazione e versamento (mese di febbraio 2025)</li>\r\n  <li>versamento imposta a saldo risultante da dichiarazione annuale IVA relativa al 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250657,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CU 2025 SINTETICA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">consegna certificazione dei redditi di lavoro dipendente e assimilati, redditi diversi e da locazione breve percepiti nell’anno 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250656,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-03-17T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INVIO CORRETTIVO CU 2025 ORDINARIA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">a seguito di errato invio delle certificazioni, possibile trasmissione delle CU correttive, senza incorrere in sanzioni</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5251667,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-03-21T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (febbraio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250662,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-03-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS – ex DMAG) di febbraio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250667,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FIRR:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento del contributo annuale al fondo indennità risoluzione rapporto di agenzia</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250668,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di febbraio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250669,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VARIAZIONE DATI MOD. EAS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica del Mod. EAS per comunicare le variazioni dati verificatesi nel 2024, rispetto a quanto già comunicato</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250670,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">BONUS PUBBLICITÀ:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione domanda telematica contenente i dati relativi agli investimenti già effettuati o da effettuare nell'anno 2025, tramite specifica piattaforma resa disponibile dall’Agenzia delle entrate</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250671,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RAVVEDIMENTO SPECIALE ANNUALITA' 2018 - 2022</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine per il versamento delle imposte sostitutive sui redditi e relative addizionali, nonché dell'IRAP (unica soluzione/prima rata) per i soggetti che hanno aderito al ravvedimento speciale per le annualità 2018 - 2022.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5252513,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250663,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INVIO CU 2025 CONTENENTE REDDITI LAVORO AUTONOMO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio telematico CU da parte dei sostituti d'imposta che hanno erogato compensi a professionisti abituali</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250664,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-03-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COMUNICAZIONE OPERAZIONI IN CONTANTI LEGATE AL TURISMO (contribuenti mensili):</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica del Mod. Polivalente (quadro TU) da parte di commercianti al minuto ed agenzie di viaggio, per comunicare i corrispettivi (compresi tra euro 2.000 ed euro 15.000) relativi ad operazioni in contanti effettuate nell'anno 2024 da parte di turisti appartenenti all’UE (non residenti in Italia) ed extraUE.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250676,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (I trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250674,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (I trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250675,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-04-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento seconda rata acconti IRPEF, IVIE, IVAFE, cedolare secca, imposta sostitutiva minimi/forfetari (quarta rata) per le persone fisiche titolari di partita IVA con ricavi/compensi fino a € 170.000.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5272055,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (marzo 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250677,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (marzo 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250679,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di marzo 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250680,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di marzo 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250681,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi mese di marzo 2025:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250682,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-04-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">COMUNICAZIONE OPERAZIONI IN CONTANTI LEGATE AL TURISMO (contribuenti trimestrali):</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica del Mod. Polivalente (quadro TU) da parte di commercianti al minuto ed agenzie di viaggio, per comunicare i corrispettivi (compresi tra euro 2.000 ed euro 15.000) relativi ad operazioni in contanti effettuate nell'anno 2024 da parte di turisti appartenenti all’UE (non residenti in Italia) ed extraUE</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250684,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (I trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250683,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-04-22T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (marzo 2025) e trimestrali (I trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250685,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-04-28T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PRECOMPILATA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Messa a disposizione al contribuente della dichiarazione precompilata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250695,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Versamento dell’imposta di bollo su scritture contabili conservate digitalmente, tramite Mod. F24 telematico</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250686,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di marzo 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250687,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250688,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Termine entro cui effettuare le modifiche all’Elenco B predisposto dall’Agenzia delle entrate e relativo alle fatture del I trimestre</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250689,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione della dichiarazione IVA relativa al 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250690,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA – RIMBORSO/COMPENSAZIONE TRIMESTRALE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">richiesta IVA a credito del I trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250691,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza relativa al I trimestre 2025 per il rimborso/compensazione del maggior onere derivante dall’incremento dell’accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250693,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">STRUTTURE SANITARIE PRIVATE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio telematico all’Agenzia delle Entrate del Mod. SSP per la comunicazione dei compensi riscossi nel 2024 da parte delle strutture sanitarie private per l’attività medica/paramedica esercitata dai singoli professionisti nella struttura stessa</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250694,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di marzo 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250696,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-04-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento seconda rata acconti IRPEF, IVIE, IVAFE, cedolare secca, imposta sostitutiva minimi/forfetari (quinta rata) per le persone fisiche titolari di partita IVA con ricavi/compensi fino a € 170.000.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5272056,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (aprile 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250702,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (aprile 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250703,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di aprile 2025 e I trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250704,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di aprile 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250705,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di aprile 2025:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250706,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento I quota fissa 2025 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250707,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250708,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-05-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi I trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250709,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-05-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (aprile 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250710,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-05-26T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione Iva IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250716,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-05-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il I trimestre 2025 (non sono interessati i contribuenti per cui l’imposta da versare risulta inferiore ad € 5.000)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250715,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di aprile 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250711,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (II trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250712,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di aprile 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250713,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al I trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250714,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-06-03T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE CONDOMINIO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute operate dal 01/2025 al 05/2025 da parte del condominio nel caso non sia superato il limite di euro 500 di ritenute operate</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250805,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU ENC:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento III rata 2024 (conguaglio) e I rata 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250717,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento acconto (o, a discrezione del contribuente, in unica soluzione annuale) anno 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250718,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti entro il 31 maggio:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250720,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (maggio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250721,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (maggio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250722,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di maggio 2025:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per la manodopera agricola relativi al IV trimestre 2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250723,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di maggio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250724,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di maggio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250725,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-06-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (maggio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250726,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-06-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IMU ENC:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione (ove previsto) da parte degli Enti non commerciali della dichiarazione IMU per le variazioni avvenute nel corso del 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250806,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250729,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza per chiedere a rimborso il credito relativo:</p><ul>\r\n  <li>al IV trimestre 2022 non compensato entro il 31/12/2024;</li>\r\n  <li>al l, II, III trimestre 2023 non compensato entro il 31/12/2024</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250789,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IMU:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione (ove previsto) della dichiarazione IMU per le variazioni avvenute nel corso del 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250790,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti dall'1 giugno al 20 giugno:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250791,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI REDDITI 2025</p><ul>\r\n  <li>imposte risultanti dalla dichiarazione delle Persone Fisiche/Società di Persone periodo d’imposta 2024 (saldo 2024 e I rata acconto 2025)</li>\r\n  <li>imposte risultanti dalla dichiarazione dei soggetti IRES con esercizio coincidente con l’anno solare e approvazione bilancio nei termini ordinari (saldo 2024 e I rata acconto 2025)</li>\r\n  <li>diritto camerale annuale</li>\r\n  <li>imposte sostitutive: cedolare secca, IVIE, IVAFE, ecc.</li>\r\n  <li>saldo 2024 e I rata acconto 2025 dei contributi IVS artigiani e commercianti sul reddito eccedente il minimale</li>\r\n  <li>saldo 2024 e I rata acconto previdenziale 2025 da parte dei professionisti iscritti alla gestione separata INPS</li>\r\n  <li>IVA per miglioramento voto ISA</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250792,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI MOD. IRAP 2025</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’IRAP (saldo 2024 e I acconto 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250793,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 – SOGGETTI PRIVI DI SOSTITUTO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento delle imposte risultanti dal Mod. 730 relativo a soggetti privi di sostituto d’imposta e a soggetti deceduti entro il 28 febbraio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250794,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta a saldo risultante da dichiarazione annuale relativa al 2024 con maggiorazione dello 0,4% per mese (o frazione) dal 16 marzo (1,6%)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250795,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE BENI D’IMPRESA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento della III° rata dell’imposta sostitutiva relativa alla rivalutazione dei beni d’impresa e partecipazioni risultanti dal bilancio dell'esercizio in corso al 31 dicembre 2018 e ancora presenti nel bilancio al 31 dicembre 2022</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250797,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di maggio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250798,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IMPOSTA DI SOGGIORNO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine per la presentazione della dichiarazione dell’imposta di soggiorno per l'anno d’imposta 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250799,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LOCAZIONI BREVI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">i soggetti che esercitano attività di intermediazione immobiliare e quelli che gestiscono portali telematici (senza incasso canone e senza applicazione ritenuta), trasmettono all’Agenzia delle Entrate, i dati relativi ai contratti di locazione breve conclusi l’anno precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250800,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. REDDITI 2025:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione presso un ufficio postale del Mod. REDDITI 2025 PF, da parte delle persone fisiche che possono presentare il modello cartaceo</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250802,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE TARI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione (ove previsto) della dichiarazione TARI per le variazioni avvenute nel corso del 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250803,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">OPZIONE PER IMPATRIATI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento in unica soluzione di un importo pari al 5% o al 10% dei redditi di lavoro dipendente/autonomo prodotti in Italia (relativi al periodo d’imposta precedente a quello di esercizio dell’opzione)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250804,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di maggio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250801,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-06-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (II trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250809,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (II trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250808,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-07-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di giugno 2025:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per i coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al I trimestre 2025</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250815,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (giugno 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250811,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (giugno 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250812,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di giugno 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250813,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di giugno 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250814,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-07-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (II trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250816,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-07-21T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate dal 21 giugno al 15 luglio:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250817,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-07-23T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (giugno 2025) e trimestrali (II trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250818,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-07-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE BENI D’IMPRESA </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento (con magg. 0,4%<span style=\"font-size: 1rem;\">) </span><span style=\"font-size: 1rem;\"> della III° rata dell’imposta sostitutiva relativa alla rivalutazione dei beni d’impresa e partecipazioni risultanti dal bilancio dell'esercizio in corso al 31 dicembre 2018 e ancora presenti nel bilancio al 31 dicembre 2022 (Legge di Bilancio 2020 e D.L. n. 23/2020)</span></p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250824,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADEGUAMENTO ISA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento IVA derivante dai maggiori ricavi/compensi dichiarati ai fini del miglioramento del profilo di affidabilità fiscale con maggiorazione dello 0,40%</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250821,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 – SOGGETTI PRIVI DI SOSTITUTO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento delle imposte (con maggiorazione dello 0,40%) risultanti dal Mod. 730 relativo a soggetti privi di sostituto d'imposta e a soggetti deceduti entro il 28 febbraio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250823,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI REDDITI 2025</p><p class=\"Testo-scadenza\" lang=\"it-IT\">Versamenti con maggiorazione dello 0,40%<span style=\"font-size: 1rem;\">:</span></p><ul>\r\n  <li>imposte risultanti dalla dichiarazione delle Persone Fisiche/Società di Persone periodo d’imposta 2024 (saldo 2024 e I rata acconto 2025)</li>\r\n  <li>imposte risultanti dalla dichiarazione dei soggetti IRES (saldo 2024 e I rata acconto 2025)</li>\r\n  <li>diritto camerale annuale</li>\r\n  <li>imposte sostitutive: cedolare secca, IVIE, IVAFE, ecc.</li>\r\n  <li>saldo 2024 e I rata acconto 2025 dei contributi IVS artigiani e commercianti sul reddito eccedente il minimale</li>\r\n  <li>saldo 2024 e I rata acconto previdenziale 2025 da parte dei professionisti iscritti alla gestione separata INPS</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250819,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTI MOD. IRAP 2025</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’IRAP (saldo 2024 e I acconto 2025) con la maggiorazione dello 0,40%</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250820,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA (pagamento con imposte sui redditi)</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta a saldo risultante dalla dichiarazione annuale relativa al 2024, applicando l'ulteriore maggiorazione dello 0,4% sulla somma dovuta</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250822,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-07-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di giugno 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250827,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA - RIMBORSO/COMPENSAZIONE TRIMESTRALE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">richiesta IVA a credito del II trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250826,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250825,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTO REDDITI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposte sui redditi e sostitutive per le società di capitali che approvano il Bilancio a 180 giorni</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250831,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONCORDATO PREVENTIVO BIENNALE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine per l'adesione alla proposta di concordato preventivo</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250832,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza relativa al II trimestre 2025 per il rimborso/compensazione del maggior onere derivante dall’incremento dell’accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250829,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di giugno 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250828,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-07-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DECORRENZA PERIODO DI SOSPENSIONE FERIALE DEI TERMINI PROCESSUALI</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250833,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-08-01T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di luglio 2025:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250839,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento III rata</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250841,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di luglio 2025 e II trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250834,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di luglio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250835,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II quota fissa 2025 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250840,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi II trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250842,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (luglio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250843,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (luglio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250838,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-08-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (luglio 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250844,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-08-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TERMINE DEL PERIODO DI SOSPENSIONE FERIALE DEI TERMINI PROCESSUALI</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250848,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250849,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-08-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">VERSAMENTO REDDITI CON MAGG. 0,4%:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposte sui redditi e sostitutive (con maggiorazione 0,4%) per le società di capitali che approvano il Bilancio a 180 giorni</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250845,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (III trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250851,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di luglio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250852,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di luglio 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250853,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-09-01T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine entro cui effettuare le modifiche all’Elenco B predisposto dall’Agenzia delle Entrate e relativo alle fatture del II trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250854,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-09-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti dal 16 luglio al 31 agosto:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250855,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-09-15T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di agosto 2025:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li><li>versamento all’INPS dei contributi per la manodopera agricola relativi al I trimestre 2025</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al II trimestre 2025</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250861,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (agosto 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250856,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (agosto 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250857,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di agosto 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250858,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di agosto 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250860,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-09-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (agosto 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250862,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-09-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al II trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250866,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica dell’istanza di rimborso dell’IVA assolta in altro Stato UE relativa al 2024 da parte di operatori residenti</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250870,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di agosto 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250871,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il II trimestre 2025 se l’imposta da versare risulta superiore o uguale ad € 5.000; va considerata anche l'imposta sulle fatture elettroniche per il I trimestre 2025 se questa era inferiore ad € 5.000</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250873,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TRASMISSIONE DATI AL SISTEMA TS</p><p class=\"Testo-scadenza\" lang=\"it-IT\">gli operatori sanitari ed assimilati trasmettono telematicamente al sistema Tessera Sanitaria i dati relativi alle prestazioni incassate nel I semestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250863,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250867,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">I datori di lavoro e gli enti pensionistici che prestano assistenza fiscale diretta, i CAF e i professionisti abilitati, per le dichiarazioni presentate da parte dei contribuenti dall'1 settembre al 30 settembre:</p><ul>\r\n  <li>consegnano al dipendente/pensionato i Modd. 730 e 730-3 elaborati;</li>\r\n  <li>inviano telematicamente all’Agenzia delle Entrate i Modd. 730, 730-1, 730-3 e 730-4.</li>\r\n</ul><p class=\"Testo-scadenza\" lang=\"it-IT\">I lavoratori dipendenti/pensionati possono trasmettere autonomamente la dichiarazione 730 tramite l'applicazione web disponibile sul sito internet dell'Agenzia delle Entrate.</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250869,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di agosto 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250872,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-09-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FONDO M. NEGRI, M. BESUSSO E A. PASTORE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dei contributi di previdenza e assistenza integrativa (III trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250877,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">il dipendente comunica al datore di lavoro/ente pensionistico di effettuare un minor o nessun acconto IRPEF/cedolare secca</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250875,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">INPS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi previdenziali per il personale domestico (III trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250876,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-10-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di settembre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250881,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi relativi al mese di settembre 2025:</p><ul>\r\n  <li>INPS: lavoratori dipendenti</li>\r\n  <li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li>\r\n  <li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li>\r\n  <li>Gestione separata INPS committenti</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250882,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di settembre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250880,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (settembre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250879,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (settembre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250878,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-10-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVINDAI E PREVINDAPI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi per dirigenti industriali (III trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250883,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-10-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 INTEGRATIVO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">lavoratori/pensionati consegnano a CAF/professionista abilitato il Mod. 730 integrativo</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250885,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (settembre 2025) e trimestrali (III trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250884,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-10-27T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE REDDITI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica del Mod. REDDITI 2025 da parte di persone fisiche, società di persone e società di capitali con esercizio coincidente con l’anno solare</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250887,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IRAP: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione telematica della dichiarazione annuale IRAP</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250888,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE OSS/IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente e dichiarazione IVA OSS relativa al trimestre precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250889,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA - RIMBORSO/COMPENSAZIONE TRIMESTRALE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">richiesta IVA a credito del III trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250890,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di settembre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250891,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di settembre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250892,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 770:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio telematico dichiarazione relativa al 2024</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250894,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOTRASPORTATORI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione all’Agenzia delle Dogane dell’istanza relativa al III trimestre 2025 per il rimborso/compensazione del maggior onere derivante dall’incremento dell’accisa sul gasolio</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250893,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CERTIFICAZIONE UNICA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">invio telematico all’Agenzia delle Entrate da parte dei sostituti d’imposta della Certificazione Unica 2025 contenente esclusivamente redditi 2024 esenti o non dichiarabili tramite Mod. 730/2025 (ad esempio, provvigioni corrisposte ad agenti di commercio titolari di partita IVA)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250895,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">termine entro cui effettuare le modifiche all’Elenco B predisposto dall’Agenzia delle Entrate e relativo alle fatture del III trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250896,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">OPZIONE PER TRASPARENZA: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione del Mod. REDDITI o di apposita comunicazione per l’adesione al regime di trasparenza da parte di società di capitali per il triennio 2023-2025. </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250886,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-10-31T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730 INTEGRATIVO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">CAF e professionisti abilitati trasmettono in via telematica all’Agenzia delle Entrate  e consegnano ai dipendenti/pensionati<span style=\"font-size: 1rem;\"> Modd. 730 e 730-3 integrativi</span></p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250897,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-11-10T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento III quota fissa 2025 su reddito minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250905,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">AUTOLIQUIDAZIONE INAIL </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento IV rata </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5251501,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CRIPTO-ATTIVITÀ</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento III°<span style=\"font-size: 1rem;\"> </span><span style=\"font-size: 1rem;\">(ultima) </span><span style=\"font-size: 1rem;\">rata imposta sostitutiva rideterminazione del valore delle cripto-attività</span></p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250898,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE TERRENI E PARTECIPAZIONI</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento della III° (ultima) rata e relativi interessi dell’imposta sostitutiva della rivalutazione delle partecipazioni e dei terreni posseduti al 1° gennaio 2023, non in regime di impresa, da parte di persone fisiche, società semplici e associazioni professionali, nonché enti non commerciali (Legge di Bilancio 2023)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250899,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (ottobre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250900,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (ottobre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250901,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento mese di ottobre 2025 e III trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250902,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di ottobre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250903,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi relativi al mese di ottobre 2025:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per coltivatori diretti, coloni e mezzadri e imprenditori agricoli professionali, relativi al III trimestre 2025</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250904,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-11-17T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI ENASARCO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi III trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250906,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-11-20T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (ottobre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250907,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-11-25T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250909,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-11-30T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RIVALUTAZIONE TERRENI E PARTECIPAZIONI ALL’1.1.2024</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II° rata dell’imposta sostitutiva dovuta </p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250918,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">MOD. 730: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">operazioni di conguaglio (sulla retribuzione erogata nel mese di novembre) dell’importo in acconto (II o unica rata)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250908,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ACCONTI IMPOSTE SUI REDDITI E IRAP: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento della II o unica rata d’acconto per l’anno 2025, di IRPEF, IVIE, IVAFE, IRES, IRAP, cedolare secca, imposta sostitutiva minimi/forfettari</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250910,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI IVS ARTIGIANI E COMMERCIANTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata acconto 2025 sul reddito eccedente il minimale</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250911,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">GESTIONE SEPARATA INPS – PROFESSIONISTI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata acconto previdenziale 2025 da parte dei professionisti iscritti alla gestione separata INPS</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250912,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">FASI: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento contributi integrativi dirigenti industriali (IV trimestre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250913,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di ottobre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250914,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIQUIDAZIONI PERIODICHE IVA: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">trasmissione telematica dei dati contabili riepilogativi delle liquidazioni periodiche IVA relative al III trimestre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250916,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di ottobre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250915,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA DI BOLLO: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’imposta di bollo sulle fatture elettroniche per il III trimestre 2025 nonché versamento dell’imposta di bollo sulle fatture elettroniche per il I e II trimestre 2025 da parte dei contribuenti per cui l’imposta risultava complessivamente inferiore ad € 5.000</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250917,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-12-01T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">TFR - IMPOSTA SOSTITUTIVA SULLA RIVALUTAZIONE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento dell’acconto sulle rivalutazioni del TFR maturate nell’anno 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250925,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">CONTRIBUTI PREVIDENZIALI:</p><ul>\r\n  <li>versamento contributi mese di novembre 2025:\r\n<ul><li>INPS: lavoratori dipendenti</li><li>INPS -&gt; Gestione ex ENPALS: lavoratori dello spettacolo</li><li>INPS -&gt; Gestione ex INPGI: giornalisti professionisti</li><li>Gestione separata INPS committenti</li></ul></li>\r\n  <li>versamento all’INPS dei contributi per la manodopera agricola relativi al II trimestre 2025</li>\r\n</ul>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250928,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMPOSTA SUGLI INTRATTENIMENTI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento imposta mese di novembre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250920,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ADDIZIONALI:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento addizionali regionale/comunale su redditi da lavoro dipendente (novembre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250922,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU: </p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento a saldo anno 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250923,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">liquidazione e versamento (mese di novembre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250926,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IMU ENC:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento II rata 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250927,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE CONDOMINIO:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute operate da giugno a novembre 2025 da parte del condominio nel caso non sia superato il limite di euro 500 di ritenute operate</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250930,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">RITENUTE:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento ritenute su redditi da lavoro dipendente e assimilati, lavoro autonomo, provvigioni, corrispettivi per contratti d’appalto nei confronti dei condomini nonché sull’ammontare dei canoni/corrispettivi relativi ai contratti di locazione breve (novembre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250924,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-12-16T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">ELENCHI INTRASTAT:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">presentazione contribuenti mensili (novembre 2025)</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250935,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">IVA:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">versamento acconto 2025 da parte dei contribuenti mensili, trimestrali e trimestrali speciali</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250934,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-12-29T00:00:00Z"
    },
    {
      "materie": "|8|4|3|2|1|",
      "dto": [
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DICHIARAZIONE IOSS:</p><p class=\"Testo-scadenza\" lang=\"it-IT\">dichiarazione IVA IOSS relativa al mese precedente</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250942,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">DENUNCIA UNIEMENS</p><p class=\"Testo-scadenza\" lang=\"it-IT\">denuncia telematica delle retribuzioni e dei contributi (INPS - ex INPDAP - ex ENPALS - ex DMAG) di novembre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250941,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">PREVIDENZA INTEGRATIVA</p><p class=\"Testo-scadenza\" lang=\"it-IT\">comunicazione al fondo di previdenza integrativa/compagnia di assicurazione dell’ammontare dei contributi versati e non dedotti nel Mod. REDDITI o 730/2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250940,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        },
        {
          "title": "<p class=\"Titolo-scadenza\" lang=\"it-IT\">LIBRO UNICO</p><p class=\"Testo-scadenza\" lang=\"it-IT\">registrazioni relative al mese di novembre 2025</p>",
          "docKey": {
            "idDatabank": 330,
            "idDatabankEnum": 330,
            "idDocMaster": 5250937,
            "idUnitaDoc": null,
            "nVigUnitaDoc": null,
            "articolo": null,
            "pagina": null,
            "materie": null
          }
        }
      ],
      "expirationDate": "2025-12-31T00:00:00Z"
    }
  ],
  "code": "OK",
  "message": null
}