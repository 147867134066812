import React, { useState, useEffect } from "react";
import { Button, Typography, Box, styled, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import utils from "../../utils";
import CliPagheTab from "./CliPagheTab"; // Import del componente CliPagheTab

const buttonStyles = {
  width: "100%",
  marginBottom: "10px",
  transition: "transform 0.2s ease, filter 0.2s ease",
  background: "#333",
  color: "white",
  textTransform: "capitalize",
};

const DashboardContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: "250px",
  padding: "20px",
  borderRadius: "10px",
  background: "linear-gradient(to bottom, #1e1e1e, #353535)",
  boxShadow: "0px 0px 5px 0px #888888",
  color: "white",
  margin: "10px auto",
  textAlign: "center",
  [theme.breakpoints.up("md")]: {
    textAlign: "left",
  },
}));

const CliPaghe = () => {
  const [hoveredPresenze, setHoveredPresenze] = useState(false);
  const [hoveredDipendenti, setHoveredDipendenti] = useState(false);
  const [hoveredBustePaga, setHoveredBustePaga] = useState(false);
  const [hoveredMalattia, setHoveredMalattia] = useState(false);
  const [hoveredCassaIntegrazione, setHoveredCassaIntegrazione] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          window.location.href = "/op";
          return;
        }
        if (success) {
          return;
        }
      }
    } catch (_) { }
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login?backto=%2Fcli%2Fpaghe";
    }
  };

  return (
    <Grid container spacing={0}>
      {/* Colonna per DashboardContainer */}
      <Grid item xs={12} sm={6} md={3}>
        <DashboardContainer>
          <Typography variant="h5" align="center" gutterBottom mt={0}>
            Sezione Paghe
          </Typography>

          <Link to="/cli/presenze" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{
                ...buttonStyles,
                background: hoveredPresenze ? "green" : "#333",
                transform: hoveredPresenze ? "scale(1.05)" : "scale(1)",
                filter: hoveredPresenze ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() => setHoveredPresenze(true)}
              onMouseLeave={() => setHoveredPresenze(false)}
            >
              Presenze
            </Button>
          </Link>

          <Link to="/cli/amministrazione/dipendenti" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{
                ...buttonStyles,
                background: hoveredDipendenti ? "green" : "#333",
                transform: hoveredDipendenti ? "scale(1.05)" : "scale(1)",
                filter: hoveredDipendenti ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() => setHoveredDipendenti(true)}
              onMouseLeave={() => setHoveredDipendenti(false)}
            >
              Dipendenti
            </Button>
          </Link>

          <Link to="/cli/bustepaga" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{
                ...buttonStyles,
                background: hoveredBustePaga ? "orange" : "#333",
                transform: hoveredBustePaga ? "scale(1.05)" : "scale(1)",
                filter: hoveredBustePaga ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() => setHoveredBustePaga(true)}
              onMouseLeave={() => setHoveredBustePaga(false)}
            >
              Buste Paga
            </Button>
          </Link>

          <Link to="/cli/malattia" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{
                ...buttonStyles,
                background: hoveredMalattia ? "red" : "#333",
                transform: hoveredMalattia ? "scale(1.05)" : "scale(1)",
                filter: hoveredMalattia ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() => setHoveredMalattia(true)}
              onMouseLeave={() => setHoveredMalattia(false)}
            >
              Malattia
            </Button>
          </Link>

          <Link to="/cli/cassaintegrazione" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              style={{
                ...buttonStyles,
                background: hoveredCassaIntegrazione ? "blue" : "#333",
                transform: hoveredCassaIntegrazione ? "scale(1.05)" : "scale(1)",
                filter: hoveredCassaIntegrazione ? "brightness(1.2)" : "brightness(1)",
              }}
              onMouseEnter={() => setHoveredCassaIntegrazione(true)}
              onMouseLeave={() => setHoveredCassaIntegrazione(false)}
            >
              Cassa Integrazione
            </Button>
          </Link>
        </DashboardContainer>
      </Grid>

      {/* Colonna per <CliPagheTab /> cards con anteprima valori dei button */}
      <Grid item xs={12} sm={6} md={9} lg={9} xl={9}>
        <Box sx={{ margin: "10px" }}>

        </Box>
      </Grid>
    </Grid>
  );
};

export default CliPaghe;
