import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  IconButton,
  Stack,
  Container,
  CircularProgress,
  ButtonBase,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import {
  ArrowBackIos,
  ArrowForwardIos,
  CloudUpload,
  Info,
} from "@mui/icons-material";
import axios from "axios";
import utils from "../../utils";
import JSZip from "jszip";
import OpEntiTabGraf from "./OpEntiTabGraf"; // Importa il componente PieCharts

const OpEntiTab = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [ticketPage, setTicketPage] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(true);
  const [statusMessageTickets, setStatusMessageTickets] = useState("");
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({ nome: "", user: 0, data: "" });
  const [selectedFileDate, setSelectedFileDate] = useState(new Date(
    Date.now() + 120 * 24 * 60 * 60 * 1000
  ).toISOString().substring(0, 10));
  const [uploadedFile, setUploadedFile] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const rowsPerPage = 8;
  const [concediStatus, setConcediStatus] = useState("concesso");
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoLoading, setInfoLoading] = useState(false);
  const [infoFields, setInfoFields] = useState({
    inps: "",
    inail: "",
    cassaEdile: "",
    note: "",
    noteProssimoDurc: ""
  });

  useEffect(() => {
    loadTickets(0);
  }, []);

  const loadTickets = async (p) => {
    try {
      const res = await axios.get(`/api/durcsrichieste?page=${p}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTickets(res.data.tickets);
        setTicketTotal(res.data.total);
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleOpenInfoDialog = (row) => {
    // Pre-popolare i campi di testo se i dati sono disponibili nel row
    setInfoFields({
      inps: row.info.inps || "",
      inail: row.info.inail || "",
      cassaEdile: row.info.cassaEdile || "",
      note: row.info.note || "",
      noteProssimoDurc: row.info.noteProssimoDurc || ""
    });
    setSelectedTicket(row);
    setInfoDialogOpen(true);
  };

  const CellStyle = {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
  };

  const centerText = {
    textAlign: "center",
  };

  const dataRowSX = {
    display: "table-row",
    ":hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  };

  const renderTableRow = (row) => (
    <ButtonBase key={row.id} sx={dataRowSX} component={TableRow}>
      <TableCell style={centerText}>{row.nome}</TableCell>
      <TableCell style={centerText}>{utils.revDate(row.dataRichiesta)}</TableCell>
      <TableCell style={centerText}>
        <IconButton
          onClick={() => handleOpenInfoDialog(row)}
          color="primary"
        >
          <Info />
        </IconButton>
      </TableCell>

      <TableCell style={centerText}>
        <Button
          variant="contained"
          startIcon={<CloudUpload />}
          sx={{ color: 'white', backgroundColor: '#1976d2' }}
          onClick={() => openUploadDialog({ nome: row.nome, user: row.user, data: row.dataRichiesta, id: row.id })}
        >
          Carica
        </Button>
      </TableCell>

      <TableCell style={centerText}>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setTicketToDelete(row);
            setOpenConfirmDialog(true);
          }}
        >
          Annulla
        </Button>
      </TableCell>
    </ButtonBase>
  );

  const handleTicketPage = (forward) => {
    if (ticketPage === 0 && !forward) {
      return;
    }
    if (rowsPerPage * (ticketPage + 1) >= ticketTotal && forward) {
      return;
    }
    const newPage = ticketPage + (forward ? 1 : -1);
    setTicketPage(newPage);
    setTicketLoading(true);
    loadTickets(newPage);
  };

  const openUploadDialog = (t) => {
    setSelectedTicket(t);
    setUploadDialogOpen(true);
  };

  const closeUploadDialog = () => {
    setSelectedFileDate("");
    setUploadedFile(null);
    setSelectedTicket({ nome: "", user: 0, data: "" });
    setUploadDialogOpen(false);
  };

  const handleCloseInfoDialog = () => {
    setSelectedTicket({ nome: "", user: 0, data: "" });
    setInfoDialogOpen(false);
  };

  const handleMultiFileChange = (e) => {
    const files = e.target.files;
    if (files.length == 1) {
      // per un file non genera lo zip, solo se sono piu di uno
      setUploadedFile({ name: files[0].name, file: files[0] });
      return;
    }

    const zip = new JSZip();

    // Aggiungi tutti i file al contenuto del file zip
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      zip.file(file.name, file);
    }

    // Crea un blob zip contenente tutti i file
    zip.generateAsync({ type: 'blob' }).then((zippedBlob) => {
      setUploadedFile({ name: 'documenti.zip', zippedBlob });
    });
  }

  const handleUpdateInfo = async () => {
    setStatusMessageTickets("");
    setInfoLoading(true);
    try {
      const res = await axios.post(`/api/aggiornainfodurc`, {
        id: selectedTicket.id,
        info: infoFields,
      }, {
        headers: utils.getAuthHeaders(),
      });
      if (res.data.success) { } else {
        setStatusMessageTickets(res.data.error);
        handleCloseInfoDialog();
      }
    } catch (error) {
      setStatusMessageTickets("Si è verificato un errore imprevisto sul nostro server.");
      handleCloseInfoDialog();
      console.log(error);
    }
    setInfoLoading(false);
  };

  const handleConfirmChanges = async (e) => {
    e.preventDefault();
    setStatusMessageTickets("");
    setTicketLoading(true);
    try {
      const payload = new FormData();

      const json = {
        id: selectedTicket.id,
        userId: selectedTicket.user,
        dataRichiesta: selectedTicket.data,
        dataScadenza: selectedFileDate,
        docDurc: uploadedFile && uploadedFile.name,
        concesso: concediStatus == 'concesso',
      };

      if (uploadedFile) {
        payload.append("doc", uploadedFile.file || uploadedFile.zippedBlob);
      }

      payload.append("data", JSON.stringify(json));

      const res = await axios.post("/api/completadurc", payload, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleDeleteTicket = async (e) => {
    e.preventDefault();
    setStatusMessageTickets("");
    setTicketLoading(true);
    try {
      const payload = { userId: ticketToDelete.user, id: ticketToDelete.id };
      const res = await axios.post(`/api/annulladurc`, payload, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };


  return (
    <Container maxWidth="xl">
      <OpEntiTabGraf />
      <Typography variant="h5" sx={{ margin: '20px 0', fontWeight: 'bold' }}>
        Richieste Durc
      </Typography>

      {ticketLoading ? (
        <Stack sx={{ my: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Box sx={{ overflowX: "auto" }}>
          <Paper sx={{ borderRadius: "10px", overflow: "hidden" }} variant="outlined">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={CellStyle}>Azienda</TableCell>
                    <TableCell style={CellStyle}>Data Richiesta</TableCell>
                    <TableCell style={CellStyle}>Info</TableCell>
                    <TableCell style={CellStyle}>Carica</TableCell>
                    <TableCell style={CellStyle}>Annulla</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tickets.map(renderTableRow)}</TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
            <Typography
              fontSize="1.1rem"
              sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
            >
              {`${ticketPage * rowsPerPage + 1} - ${ticketPage * rowsPerPage + tickets.length} di ${ticketTotal}`}
            </Typography>
            <IconButton
              onClick={() => handleTicketPage(false)}
              disabled={ticketPage === 0}
            >
              <ArrowBackIos />
            </IconButton>
            <IconButton
              onClick={() => handleTicketPage(true)}
              disabled={rowsPerPage * (ticketPage + 1) >= ticketTotal}
            >
              <ArrowForwardIos />
            </IconButton>
          </Stack>
          {(statusMessageTickets) && (
            <Paper
              variant="outlined"
              sx={{
                p: 2,
                my: 1,
                mx: 2,
                flexGrow: 1,
                backgroundColor: "#e57373",
                borderColor: "#d32f2f",
                color: "black",
              }}
              role="alert"
            >
              {statusMessageTickets}
            </Paper>
          )}
        </Box>
      )}

      {/* Dialog per annullamento richiesta */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Conferma annullamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler annullare la richiesta per l'azienda {ticketToDelete?.nome}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleDeleteTicket} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per il caricamento */}
      <Dialog open={uploadDialogOpen} onClose={closeUploadDialog}>
        <DialogTitle>Completa DURC</DialogTitle>
        <DialogContent>
          <Typography>Nuova data di SCADENZA</Typography>
          <TextField
            type="date"
            value={selectedFileDate}
            onChange={(e) => setSelectedFileDate(e.target.value)}
            fullWidth
            required
          />
          <RadioGroup
            value={concediStatus}
            onChange={(e) => setConcediStatus(e.target.value)}
            row
            sx={{ marginTop: "1rem" }}
          >
            <FormControlLabel
              value="concesso"
              control={<Radio />}
              label="Concesso"
            />
            <FormControlLabel
              value="non_concesso"
              control={<Radio />}
              label="Non Concesso"
            />
          </RadioGroup>
          <input
            type="file"
            onChange={handleMultiFileChange}
            style={{ marginTop: "1rem" }}
            multiple
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeUploadDialog}>Annulla</Button>
          <Button
            onClick={handleConfirmChanges}
            variant="contained"
            color="primary"
            disabled={concediStatus == 'concesso' && (!uploadedFile || !selectedFileDate)}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per le info */}
      <Dialog open={infoDialogOpen} onClose={handleCloseInfoDialog}>
        <DialogTitle>Informazioni Supplementari</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="INPS"
                value={infoFields.inps}
                onChange={(e) => setInfoFields({ ...infoFields, inps: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="INAIL"
                value={infoFields.inail}
                onChange={(e) => setInfoFields({ ...infoFields, inail: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Cassa Edile"
                value={infoFields.cassaEdile}
                onChange={(e) => setInfoFields({ ...infoFields, cassaEdile: e.target.value })}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          <TextField
            label="Note"
            value={infoFields.note}
            onChange={(e) => setInfoFields({ ...infoFields, note: e.target.value })}
            fullWidth
            multiline
            rows={5}
            margin="normal"
          />
          <TextField
            label="Note Prossimo DURC"
            value={infoFields.noteProssimoDurc}
            onChange={(e) => setInfoFields({ ...infoFields, noteProssimoDurc: e.target.value })}
            fullWidth
            multiline
            rows={5}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog}>Chiudi</Button>
          <LoadingButton loading={infoLoading} variant="contained" onClick={handleUpdateInfo}>
            Salva
          </LoadingButton>
        </DialogActions>
      </Dialog>

    </Container>
  );
};

export default OpEntiTab;
