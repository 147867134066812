import React, { useState, useEffect } from 'react';
import {
  Grid, Autocomplete, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, CircularProgress,
  Typography, Box, Pagination, MenuItem, Select, Stack, Container, FormControl, ButtonBase
} from '@mui/material';

import axios from 'axios';
import utils from '../../utils';
import ArchivioDialog from './OpPagArchivioDialog';

const dataRowSX = {
  display: "table-row",
  ":hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

// filtro delle aziende
const filter = (aziende, search) => {
  const filtered = aziende.filter(a => a.ragione_sociale.toLowerCase()
    .replace(/[^\p{L}\p{N}]/gu, '')
    .includes(search.toLowerCase().replace(/[^\p{L}\p{N}]/gu, '')));
  return filtered;
}

const Archivio = () => {
  const [allAziende, setAllAziende] = useState([]);
  const [aziende, setAziende] = useState([]);
  const [selectedAzienda, setSelectedAzienda] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [masters, setMasters] = useState([{ id: -1, nome: 'Tutti' }, { id: 0, nome: 'Studio Romeo' }]);
  const [master, setMaster] = useState(-1);
  const [userTotal, setUserTotal] = useState(0);
  const [userLoading, setUserLoading] = useState(true);
  const [statusMessageUsers, setStatusMessageUsers] = useState("");
  const [error, setError] = useState('');

  useEffect(() => {
    loadData();
    loadAziende(-1);
    loadMasters();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && me.op) {
          setUserLoading(false);
          return; // stay on the page
        } else if (error) {
          setStatusMessageUsers(error);
        }
      }
    } catch (error) {
      setStatusMessageUsers(error);
    }
    setUserLoading(false);
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadAziende = async (newMaster) => {
    setStatusMessageUsers("");
    try {
      let string = `/api/getusers`;
      if (newMaster > -1) {
        string += '?master=' + newMaster;
      }
      const res = await axios.get(string, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setAllAziende(res.data.users);
        setAziende(res.data.users);
        setUserTotal(res.data.total);
      } else {
        setStatusMessageUsers(error);
      }
    } catch (error) {
      setStatusMessageUsers(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setUserLoading(false);
  };

  const loadMasters = async () => {
    try {
      const res = await axios.get(`/api/listamaster`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        const baseMasters = [{ id: -1, nome: 'Tutti' }, { id: 0, nome: 'Studio Romeo' }];
        baseMasters.push(...res.data.masters);
        setMasters(baseMasters);
      } else {
        setStatusMessageUsers(error);
      }
    } catch (error) {
      setStatusMessageUsers(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setUserLoading(false);
  };

  const handleMasterChange = (newMaster) => {
    setUserLoading(true);
    setMaster(newMaster);
    setPage(0);
    setSearchTerm("");
    loadAziende(newMaster);
  };

  const handleRowClick = (azienda) => {
    setSelectedAzienda(azienda);
    setOpenDialog(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredAziende = aziende.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h4" align="center" fontWeight="bold" marginTop={2}>
          Archivio Documenti Aziende
        </Typography>
      </Grid>
      {userLoading ? (
        <Stack sx={{ mt: 3 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Container maxWidth="xxl">
          <Box
            borderRadius={2}
            border="1px solid #ccc"
            sx={{ px: 3, pt: 3, pb: 2, mt: 2 }}
          >
            <Typography
              variant="h5"
              component="div"
              fontWeight="bold"
              sx={{ flexGrow: 1, marginBottom: 2 }}
            >
              Clienti Studio: {userTotal}
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Grid container spacing={2} alignItems="center">
                {/* Campo di ricerca per Ragione Sociale */}
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
                    <TextField
                      value={searchTerm}
                      onChange={(event) => {
                        const newValue = event.target.value;
                        const filteredAziende = filter(allAziende, newValue);
                        setAziende(filteredAziende.slice(0, 15));
                        setUserTotal(filteredAziende.length);
                        setPage(0);
                        setSearchTerm(newValue);
                      }}
                      label="Cerca per Ragione Sociale"
                      variant="outlined"
                      size="medium"
                      sx={{ flexGrow: 1, mb: { xs: 1, sm: 0 }, mr: { sm: 1 } }}
                    />
                  </FormControl>
                </Grid>

                {/* Campo di selezione Studio */}
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
                    <Autocomplete
                      id="master"
                      value={master}
                      onChange={(event, newValue) => {
                        handleMasterChange(newValue);
                      }}
                      options={masters.map((az) => az.id)}
                      getOptionLabel={(id) => {
                        const a = masters.filter(az => az.id == id)[0];
                        if (a.id > 0) {
                          return `${a.nome} (${a.id})`;
                        }
                        return a.nome;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Studio"
                          variant="outlined"
                          size="medium"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>

            {userLoading ? (
              <Stack sx={{ my: 3 }} alignItems="center">
                <CircularProgress disableShrink />
              </Stack>
            ) : (
              <div>
                <TableContainer sx={{ width: "100%", overflowX: "auto", borderRadius: '16px', }}>
                  <Table size="medium" sx={{ mt: 0 }} >
                    <TableHead >
                      <TableRow
                        style={{
                          backgroundColor: '#333',
                          color: '#fff',
                        }}
                      >
                        <TableCell style={{ width: '5%', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>ID</TableCell>
                        <TableCell style={{ width: '25%', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Ragione Sociale</TableCell>
                        <TableCell style={{ width: '15%', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Nome</TableCell>
                        <TableCell style={{ width: '15%', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Cognome</TableCell>
                        <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Telefono</TableCell>
                        <TableCell style={{ width: '20%', color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>Email</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredAziende.map((azienda) => (
                        <ButtonBase
                          key={azienda.id}
                          component={TableRow}
                          onClick={() => handleRowClick(azienda)}
                          sx={dataRowSX}
                        >
                          <TableCell style={{ textAlign: 'center' }}>{azienda.id}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{azienda.ragione_sociale}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{azienda.nome}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{azienda.cognome}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{azienda.telefono}</TableCell>
                          <TableCell style={{ textAlign: 'center' }}>{azienda.email}</TableCell>
                        </ButtonBase>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" mt={2}>
                  <Select
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    variant="outlined"
                    style={{ marginRight: '8px', maxWidth: '80px', height: '35px', marginBottom: { xs: '8px', sm: '0' } }}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                  <Pagination
                    count={Math.ceil(aziende.length / rowsPerPage)}
                    page={page + 1}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                  />
                </Box>
              </div>
            )}
            {statusMessageUsers && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  my: 1,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                }}
                role="alert"
              >
                {statusMessageUsers}
              </Paper>
            )}
          </Box>
        </Container>
      )}

      <ArchivioDialog setError={setError} setOpenDialog={setOpenDialog} openDialog={openDialog} azienda={selectedAzienda} />

      {error && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setError('')}
          message={error}
        />
      )}
    </div>
  );
};

export default Archivio;
