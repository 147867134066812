import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Divider, Box, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const OpGuida = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const videoLinks = {
    contrattualistica: [
      { id: 1, title: 'Video Tutorial Contrattualistica', url: 'https://www.youtube.com/embed/gfQE5JzOgLQ' },
    ],
    paghe: [
      { id: 1, title: 'Video Tutorial Paghe', url: 'https://www.youtube.com/embed/U13XZMyvZ1M' },  // Modified URL
    ],
    enti: [
      { id: 1, title: 'Video Tutorial Enti', url: 'https://www.youtube.com/embed/M_wqxrJJ1Og' },
    ],
  };

  return (
    <Container maxWidth="xl" style={{ padding: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom style={{ textAlign: 'center', marginBottom: '40px', color: '#000000' }}>
        Guida Software
      </Typography>

      {Object.keys(videoLinks).map((category) => (
        <Accordion
          key={category}
          expanded={expanded === category}
          onChange={handleChange(category)}
          sx={{ marginBottom: '20px', borderRadius: '8px', boxShadow: 3 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${category}-content`}
            id={`${category}-header`}
            sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
          >
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
              <PlayCircleOutlineIcon sx={{ marginRight: '8px' }} />
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa' }}>
            {videoLinks[category].map((video, index) => (
              <Box key={video.id} sx={{ marginBottom: '20px' }}>
                <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>{video.title}</Typography>
                <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                  <iframe
                    width="100%"
                    height="315"
                    src={video.url}
                    title={video.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Paper>
                {index < videoLinks[category].length - 1 && <Divider sx={{ margin: '20px 0' }} />}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
};

export default OpGuida;
