import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Avatar, Grid, Typography, Badge, } from "@mui/material";
import axios from "axios";
import utils from "../../utils";
import OpPagChart from "./OpPagChart";

const LinkButton = ({ to, isHovered, onMouseEnter, onMouseLeave, color, children }) => (
  <Link
    to={to}
    style={{
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      padding: "8px 0",
      borderRadius: "4px",
      color: "white",
      transition: "transform 0.2s, filter 0.2s, box-shadow 0.2s",
      boxShadow: "10px 10px 10px rgba(10, 10, 0, 0.5)",
      marginBottom: "10px",
      backgroundColor: isHovered ? color : "#333",
      transform: isHovered ? "scale(1.05)" : "scale(1)",
      filter: isHovered ? "brightness(1.2)" : "brightness(1)",
    }}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {children}
  </Link>
);

const OpPaghe = () => {

  const now = new Date();
  const currentMonth = now.getMonth();
  const yearMap = {};
  yearMap[currentMonth] = now.getFullYear();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  yearMap[previousMonth] = currentMonth === 0 ? now.getFullYear() - 1 : now.getFullYear();

  const [selectedMonth, setSelectedMonth] = useState(previousMonth);

  const [username, setUsername] = useState("");
  const [hoverState, setHoverState] = useState({
    presenze: false,
    bustepaghe: false,
    paghefatte: false,
    malattia: false,
    cassaintegrazione: false,
    archivio: false,
  });

  const [richiesteModificaCount, setRichiesteModificaCount] = useState(0);
  const [error, setError] = useState('');

  const [userId, setUserId] = useState(0);
  const avatarUrl = `/api/avatar?id=${userId}`;

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await axios.get("/api/me", {
          headers: utils.getAuthHeaders(),
          validateStatus: () => true,
        });
        if (res.status === 200) {
          const { success, me } = res.data;
          if (success) {
            if (!me.op) {
              window.location.href = "/cli";
              return;
            }
            setUserId(me.id); // Aggiungi questa riga per impostare l'ID dell'utente
            setUsername(
              `${me.nome.charAt(0).toUpperCase()}${me.nome.substring(1).toLowerCase()} ${me.cognome.charAt(0).toUpperCase()}.`
            );
            return;
          }
        }
      } catch (_) { }
      if (process.env.REACT_APP_ENV !== "test") {
        window.location.href = "/login";
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadRichiesteModifica = async (m) => {
      try {
        const res = await axios.get(`/api/aziendeperpaghe?a=${yearMap[m]}&m=${m}`, {
          headers: utils.getAuthHeaders(),
        });
        const { success, error } = res.data;
        if (success) {
          setRichiesteModificaCount(res.data.aziende.reduce((a, c) => a + c.richieste, 0));
        } else {
          setError(error);
        }
      } catch (error) {
        setError('Errore nel caricamento delle aziende: ' + error.message);
      }
    };
    loadRichiesteModifica(selectedMonth);
  }, [selectedMonth]);

  const handleMouseEnter = (section) => () => {
    setHoverState((prev) => ({ ...prev, [section]: true }));
  };

  const handleMouseLeave = (section) => () => {
    setHoverState((prev) => ({ ...prev, [section]: false }));
  };

  return (
    <Grid container justifyContent="center" alignItems="flex-start" spacing={0} sx={{ mt: 1, mb: 1 }}>
      <Grid
        item xs={12} sm={4} md={3} lg={2} xl={2}
        sx={{
          background: "linear-gradient(to bottom, #1e1e1e, #353535)",
          p: 2,
          borderRadius: "2vh",
          height: 420,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.5)",
          m: 1
        }}
      >
        <Grid container alignItems="center" sx={{ pb: 2 }}>
          <Avatar alt={username} src={avatarUrl} sx={{ width: 50, height: 50, mr: 1 }} />
          <Typography variant="h6" color="white">{username}</Typography>
        </Grid>
        <LinkButton
          to="/op/paghe/presenze"
          isHovered={hoverState.presenze}
          onMouseEnter={handleMouseEnter('presenze')}
          onMouseLeave={handleMouseLeave('presenze')}
          color="darkgreen"
        >
          Presenze
        </LinkButton>
        <LinkButton
          to="/op/amministrazione/dipendenti"
          isHovered={hoverState.dipendenti}
          onMouseEnter={handleMouseEnter('dipendenti')}
          onMouseLeave={handleMouseLeave('dipendenti')}
          color="darkblue"
        >
          Dipendenti
        </LinkButton>
        <LinkButton
          to="/op/paghe/bustepaghe"
          isHovered={hoverState.bustepaghe}
          onMouseEnter={handleMouseEnter('bustepaghe')}
          onMouseLeave={handleMouseLeave('bustepaghe')}
          color="darkorange"
          style={{ position: 'relative' }}
        >
          Buste Paghe
          {richiesteModificaCount > 0 && (
            <Badge
              badgeContent={richiesteModificaCount}
              color="warning"
              sx={{ position: 'absolute', right: 25 }}
            />
          )}
        </LinkButton>
        <LinkButton
          to="/op/paghe/paghefatte"
          isHovered={hoverState.paghefatte}
          onMouseEnter={handleMouseEnter('paghefatte')}
          onMouseLeave={handleMouseLeave('paghefatte')}
          color="purple"
        >
          Paghe Fatte
        </LinkButton>
        <LinkButton
          to="/op/paghe/malattia"
          isHovered={hoverState.malattia}
          onMouseEnter={handleMouseEnter('malattia')}
          onMouseLeave={handleMouseLeave('malattia')}
          color="darkred"
        >
          Malattia
        </LinkButton>
        <LinkButton
          to="/op/paghe/cassaintegrazione"
          isHovered={hoverState.cassaintegrazione}
          onMouseEnter={handleMouseEnter('cassaintegrazione')}
          onMouseLeave={handleMouseLeave('cassaintegrazione')}
          color="darkorchid"
        >
          Cassa Integrazione
        </LinkButton>
        <LinkButton
          to="/op/paghe/archivio"
          isHovered={hoverState.archivio}
          onMouseEnter={handleMouseEnter('archivio')}
          onMouseLeave={handleMouseLeave('archivio')}
          color="gray"
        >
          Archivio
        </LinkButton>

      </Grid>
      <Grid
        item xs={12} md={9} container spacing={0} justifyContent="center" alignItems="center"
        sx={{ m: 4 }}
      >
        <OpPagChart selectedMonth={selectedMonth} setSelectedMonth={setSelectedMonth} />
      </Grid>
    </Grid>
  );
};

export default OpPaghe;
