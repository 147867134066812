import React, { useState, useEffect } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Container, Divider,
  Box, Paper, Stack, CircularProgress, Tooltip, IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import axios from "axios";
import utils from "../../utils";

const CliGuida = () => {
  const [expanded, setExpanded] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get(`/api/newsclienti`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setNewsList(res.data.news);
      } else {
        console.error(error);
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const videoLinks = {
    contrattualistica: [
      { id: 1, title: 'Video Tutorial Contrattualistica', url: 'https://www.youtube.com/embed/oQTjHr7KSlw' },
    ],
    paghe: [
      { id: 1, title: 'Video Tutorial Paghe', url: 'https://www.youtube.com/embed/Bq16N11ysuI' },  // Modified URL
    ],
    enti: [
      { id: 1, title: 'Video Tutorial Enti', url: 'https://www.youtube.com/embed/9qguDk2edZ8' },
    ],
  };

  // Funzione per gestire la stampa
  const handlePrint = (news) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print News</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .news-content { white-space: pre-line; }
            .news-header { text-align: center; margin-bottom: 20px; }
            .news-title { font-size: 1.5em; font-weight: bold; }
            .news-date { font-size: 1em; color: gray; }
            .header-image {
            width: 30%; /* Ridimensiona l'immagine */
            max-width: 300px; /* Limita la larghezza massima */
            margin: -50px auto 0px; /* Sposta l'immagine più in alto e centra */
            display: block;
          }
          </style>
        </head>
        <body>
        <img src="/CartaIntestata.svg" alt="Carta Intestata" class="header-image" />
          <div class="news-header">
            <div class="news-title">${news.title}</div>
            <div class="news-date">${new Date(news.date).toLocaleDateString()}</div>
          </div>
          <div class="news-content">${news.content}</div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Container maxWidth="xl" style={{ padding: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom style={{ textAlign: 'center', marginBottom: '40px', color: '#000000' }}>
        Guida Software
      </Typography>

      {Object.keys(videoLinks).map((category) => (
        <Accordion
          key={category}
          expanded={expanded === category}
          onChange={handleChange(category)}
          sx={{ marginBottom: '20px', borderRadius: '8px', boxShadow: 3 }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${category}-content`}
            id={`${category}-header`}
            sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
          >
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
              <PlayCircleOutlineIcon sx={{ marginRight: '8px' }} />
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: '#fafafa' }}>
            {videoLinks[category].map((video, index) => (
              <Box key={video.id} sx={{ marginBottom: '20px' }}>
                <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>{video.title}</Typography>
                <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                  <iframe
                    width="100%"
                    height="315"
                    src={video.url}
                    title={video.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Paper>
                {index < videoLinks[category].length - 1 && <Divider sx={{ margin: '20px 0' }} />}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}

      <Divider sx={{ my: 1 }} />

      {/* News Section */}
      <Box sx={{ marginTop: '40px' }}>
        <Typography variant="h4" component="h2" gutterBottom style={{ textAlign: 'center', marginBottom: '20px', color: '#000000' }}>
          News
        </Typography>
        {loading ? <Stack sx={{ my: 5 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack> : <div>
          {newsList.map((news) => (
            <Accordion
              key={news.id}
              expanded={expanded === `news-${news.id}`}
              onChange={handleChange(`news-${news.id}`)}
              sx={{ marginBottom: '20px', borderRadius: '8px', boxShadow: 3 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`news-${news.id}-content`}
                id={`news-${news.id}-header`}
                sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {news.title}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Categoria: {news.category} | Data: {new Date(news.date).toLocaleDateString()}
                    </Typography>
                  </Box>
                  <PrintOutlinedIcon
                    sx={{ cursor: 'pointer', color: 'gray' }}
                    onClick={() => handlePrint(news)}
                  />
                </Box>

              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#fafafa' }}>
                <Typography variant="body1" sx={{ marginTop: 1, whiteSpace: 'pre-line' }}>
                  {news.content}
                </Typography>
                {news.files && news.files.length > 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Divider sx={{ mb: 1 }} />
                    <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>File Allegati:</Typography>
                    {news.files.map((file, index) => (
                      <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Typography variant="body2">{file.name}</Typography>
                        {file.name.endsWith('.pdf') && (
                          <Tooltip title="Anteprima">
                            <IconButton onClick={() => utils.cliOpenPdfInNewTab(file.id)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Scarica">
                          <IconButton onClick={() => utils.cliDownload(file.id)} download>
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    ))}
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>}
      </Box>
    </Container>
  );
};

export default CliGuida;
