import axios from "axios";
import utils from "../../utils";
import React, { useState, useEffect } from "react";
import {
  Box, Container, TableContainer, Typography, ButtonBase, Table, TableHead, TableRow, TableCell, TableBody,
  Paper, IconButton, Stack, CircularProgress,
} from "@mui/material";
import {
  ArrowBackIos, ArrowForwardIos, CheckCircleOutline, HighlightOffOutlined,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const dataRowSX = {
  display: "table-row",
  ":hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
};

const OpAssunzione = () => {
  const [tickets, setTickets] = useState([]);
  const [ticketTotal, setTicketTotal] = useState(0);
  const [ticketPage, setTicketPage] = useState(0);
  const [ticketLoading, setTicketLoading] = useState(true);
  const [statusMessageTickets, setStatusMessageTickets] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
    loadTickets(0);
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, error, me } = res.data;
        if (success && me.op) {
          setIsLoading(false);
          return; // stay on the page
        } else if (error) {
          setStatusMessageTickets(error);
        }
      }
    } catch (error) {
      setStatusMessageTickets(error);
    }
    setIsLoading(false);
    if (process.env.REACT_APP_ENV !== "test") {
      window.location.href = "/login";
    }
  };

  const loadTickets = async (p) => {
    try {
      const res = await axios.get(`/api/tickets?type=as&page=${p}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setTickets(res.data.tickets);
        setTicketTotal(res.data.total);
      } else {
        setStatusMessageTickets(error);
      }
    } catch (error) {
      setStatusMessageTickets(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setTicketLoading(false);
  };

  const handleTicketPage = (forward) => {
    if (ticketPage === 0 && !forward) {
      return;
    }
    if (15 * (ticketPage + 1) >= ticketTotal && forward) {
      return;
    }
    const newPage = ticketPage + (forward ? 1 : -1);
    setTicketPage(newPage);
    setTicketLoading(true);
    loadTickets(newPage);
  };

  return (
    <div>
      {isLoading ? (
        <Stack sx={{ mt: 5 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Container>
          <Box
            borderRadius={2}
            border="1px solid #ccc"
            sx={{ px: 3, pt: 3, pb: 2, mt: 5 }}
          >
            <Typography variant="h4" gutterBottom sx={{ pl: 1, mb: 2 }}>
              Assunzioni
            </Typography>

            {ticketLoading ? (
              <Stack sx={{ my: 3 }} alignItems="center">
                <CircularProgress disableShrink />
              </Stack>
            ) : (
              <div>
                <TableContainer sx={{ width: "100%", overflowX: "auto" }}>
                  <Table size="small" sx={{ mt: 3 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          ID Pratica
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Azienda
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Lavoratore
                        </TableCell>
                        <TableCell sx={{ color: "rgba(0, 0, 0, 0.4)" }}>
                          Data Richiesta
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "rgba(0, 0, 0, 0.4)" }}
                        >
                          Pagato
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ color: "rgba(0, 0, 0, 0.4)" }}
                        >
                          Completato
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tickets.map((ticket) => (
                        <ButtonBase
                          component={Link}
                          to={`/op/assunzioni/detail?id=${ticket.id}`}
                          key={ticket.id}
                          sx={dataRowSX}
                        >
                          <TableCell>{ticket.id}</TableCell>
                          <TableCell>{ticket.ragione_sociale}</TableCell>
                          <TableCell>{ticket.cognome + ' ' + ticket.nome}</TableCell>
                          <TableCell>{utils.revDate(ticket.timestamp.substring(0, 10))}</TableCell>
                          <TableCell align="center">
                            {ticket.pagato ? (
                              <CheckCircleOutline color="success" />
                            ) : (
                              <HighlightOffOutlined color="error" />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {ticket.completato ? (
                              <CheckCircleOutline color="success" />
                            ) : (
                              <HighlightOffOutlined color="error" />
                            )}
                          </TableCell>
                        </ButtonBase>
                      ))}
                    </TableBody>

                  </Table>
                </TableContainer>
                <Stack direction="row" alignItems="center" sx={{ pt: 1 }}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}
                  />
                  <Typography
                    fontSize="1.1rem"
                    sx={{ pr: 2, color: "rgba(0, 0, 0, 0.8)" }}
                  >
                    {`${ticketPage * 15 + 1} - ${ticketPage * 15 + tickets.length
                      } di ${ticketTotal}`}
                  </Typography>
                  <IconButton
                    onClick={() => handleTicketPage(false)}
                    disabled={ticketPage === 0}
                  >
                    <ArrowBackIos />
                  </IconButton>
                  <IconButton
                    onClick={() => handleTicketPage(true)}
                    disabled={15 * (ticketPage + 1) >= ticketTotal}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Stack>
              </div>
            )}
            {statusMessageTickets && (
              <Paper
                variant="outlined"
                sx={{
                  p: 2,
                  my: 1,
                  flexGrow: 1,
                  backgroundColor: "#e57373",
                  borderColor: "#d32f2f",
                  color: "black",
                }}
                role="alert"
              >
                {statusMessageTickets}
              </Paper>
            )}
          </Box>
        </Container>
      )}
    </div>
  );
};

export default OpAssunzione;