import React, { useState, useEffect } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Container, Divider, Box, Paper, TextField, Button, Grid,
  Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme, IconButton, Tooltip, Snackbar, Alert,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon, PlayCircleOutline as PlayCircleOutlineIcon, Add as AddIcon,
  Delete as DeleteIcon, Remove as RemoveIcon, Search as SearchIcon, Visibility as VisibilityIcon, FileDownload as DownloadIcon,
  FileUpload as UploadIcon,
} from '@mui/icons-material';
import axios from 'axios';
import utils from '../../utils';

const Formazione = () => {
  const [expanded, setExpanded] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredVideos, setFilteredVideos] = useState([]);
  const [videoLinks, setVideoLinks] = useState({});
  const [openVideoDialog, setOpenVideoDialog] = useState(false);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [videoToDelete, setVideoToDelete] = useState(null);
  const [newVideo, setNewVideo] = useState({ title: '', id: '', category: '' });
  const [newCategory, setNewCategory] = useState('');
  const [categoryToDelete, setCategoryToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error'); // "error" o "success"
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    try {
      const res = await axios.get("/api/me", {
        headers: utils.getAuthHeaders(),
        validateStatus: () => true,
      });
      if (res.status === 200) {
        const { success, me } = res.data;
        if (success && me.op) {
          setIsAdmin(me.aree.split(',').includes('amministrazione'));
        } else {
          window.location.href = "/cli";
        }
      }
    } catch (_) { }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Recupera le categorie e i video all'inizio
    const fetchVideos = async () => {
      try {
        const res = await axios.get('/api/formazione', {
          headers: utils.getAuthHeaders(),
        });
        const { success, error } = res.data;
        if (success) {
          setVideoLinks(res.data.videos);
        } else {
          showSnackbar(error, 'error');
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchVideos();
  }, []);

  useEffect(() => {
    setFilteredVideos(Object.values(videoLinks).flat());
  }, [videoLinks]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Cerca Video nelle categorie
  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearch(searchValue);

    const lowercasedValue = searchValue.toLowerCase();
    const foundVideo = Object.values(videoLinks)
      .flatMap((videos, index) => videos.map((video) => ({ ...video, category: Object.keys(videoLinks)[index] })))
      .find((video) => video.title.toLowerCase().includes(lowercasedValue));

    setExpanded(foundVideo ? foundVideo.category : false);
    setFilteredVideos(foundVideo ? [foundVideo] : []);
  };

  const handleAddVideo = (e, category) => {
    e.preventDefault();
    setOpenVideoDialog(true);
    setNewVideo({ ...newVideo, category });
  };

  const handleAddCategory = () => {
    setOpenCategoryDialog(true);
  };

  // Salva il Video Inserito
  const handleSaveVideo = async () => {
    try {
      const { success, error, id } = (await axios.post('/api/aggiungivideoformazione', {
        category: newVideo.category, title: newVideo.title, id: newVideo.id
      }, {
        headers: utils.getAuthHeaders(),
      })).data;
      if (!success) {
        showSnackbar(error, 'error');
        console.error(error);
        return;
      }

      setVideoLinks({
        ...videoLinks,
        [newVideo.category]: [...videoLinks[newVideo.category], { ...newVideo, id }],
      });

      setNewVideo({ title: '', id: '', category: '' });
      setOpenVideoDialog(false);

      showSnackbar('Video aggiunto con successo!', 'success');
    } catch (error) {
      console.error('Errore nel salvataggio del video:', error);
      showSnackbar('Errore nel salvataggio del video.', 'error');
    }
  };

  // Salva la Categoria
  const handleSaveCategory = async () => {
    try {
      const { success, error } = (await axios.post('/api/aggiungicategoriaformazione', { name: newCategory }, {
        headers: utils.getAuthHeaders(),
      })).data;
      if (!success) {
        showSnackbar(error, 'error');
        console.error(error);
        return;
      }
      setVideoLinks({
        ...videoLinks,
        [newCategory]: [],
      });
      setNewCategory('');
      setOpenCategoryDialog(false);

      showSnackbar('Categoria aggiunta con successo!', 'success');
    } catch (error) {
      console.error('Errore nel salvataggio della sezione:', error);
      showSnackbar('Errore nel salvataggio della categoria.', 'error');
    }
  };

  const handleDeleteVideo = (video) => {
    setVideoToDelete(video);
    setOpenDeleteDialog(true);
  };

  // Elimina la categoria
  const handleDeleteCategory = (e, category) => {
    e.preventDefault();
    setCategoryToDelete(category);
    setOpenDeleteDialog(true);
  };

  const confirmDeleteCategory = async () => {
    try {
      const { success, error } = (await axios.post(`/api/eliminacategoriaformazione`, { name: categoryToDelete }, {
        headers: utils.getAuthHeaders()
      })).data;
      if (!success) {
        showSnackbar(error, 'error');
        console.error(error);
        return;
      }
      const updatedCategories = { ...videoLinks };
      delete updatedCategories[categoryToDelete];
      setVideoLinks(updatedCategories);
      setOpenDeleteDialog(false);

      showSnackbar('Categoria eliminata con successo!', 'success');
    } catch (error) {
      console.error('Errore nell\'eliminazione della categoria:', error);
      showSnackbar('Errore nell\'eliminazione della categoria.', 'error');
    }
  };

  // Elimina il video
  const confirmDeleteVideo = async () => {
    try {
      const { success, error } = (await axios.post(`/api/eliminavideoformazione`,
        { id: videoToDelete.id, category: videoToDelete.category }, {
        headers: utils.getAuthHeaders()
      })).data;
      if (!success) {
        showSnackbar(error, 'error');
        console.error(error);
        return;
      }
      const updatedVideos = videoLinks[videoToDelete.category].filter(
        (video) => video.id !== videoToDelete.id
      );
      setVideoLinks({
        ...videoLinks,
        [videoToDelete.category]: updatedVideos,
      });
      setOpenDeleteDialog(false);

      showSnackbar('Video eliminato con successo!', 'success');
    } catch (error) {
      console.error('Errore nell\'eliminazione del video:', error);
      showSnackbar('Errore nell\'eliminazione del video.', 'error');
    }
  };

  const handleCloseDialog = () => {
    setCategoryToDelete(null);
    setVideoToDelete(null);
    setOpenDeleteDialog(false);
  };

  const sortedCategories = Object.keys(videoLinks).sort(); // Ordina le categorie in ordine alfabetico

  // Snackbar per la gestione degli errori
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="xxl">
      <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              <Typography
                variant="h4"
                gutterBottom
                style={{ margin: 0, marginRight: '16px', display: 'flex', alignItems: 'center' }}
              >
                Formazione
              </Typography>
              {isAdmin && <Tooltip title="Aggiungi Sezione">
                <IconButton onClick={handleAddCategory} color="success">
                  <AddIcon />
                </IconButton>
              </Tooltip>}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
            <TextField
              label="Cerca video"
              value={search}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
              InputProps={{
                startAdornment: (
                  <SearchIcon style={{ marginRight: '8px' }} />
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {search && filteredVideos.length === 0 ? (
        <Typography variant="h6" style={{ textAlign: 'center', marginTop: '20px' }}>
          Nessun risultato trovato
        </Typography>
      ) : (
        sortedCategories.map((category) => (
          <Accordion
            key={category}
            expanded={expanded === category}
            onChange={handleChange(category)}
            sx={{ marginBottom: '20px', borderRadius: '8px', boxShadow: 3 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${category}-content`}
              id={`${category}-header`}
              sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
            >
              <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                  <PlayCircleOutlineIcon sx={{ marginRight: '8px' }} />
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </Typography>

                {/* Box addvideo e remove categoria */}
                <Box display="flex" justifyContent="flex-end" alignItems="center">
                  {isAdmin && <Tooltip title="Aggiungi Video">
                    <IconButton onClick={(e) => handleAddVideo(e, category)} color="primary">
                      <AddIcon />
                    </IconButton>
                  </Tooltip>}

                  {isAdmin && <Tooltip title="Elimina Categoria">
                    <IconButton onClick={(e) => handleDeleteCategory(e, category)} color="error">
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>}
                </Box>

              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#fafafa' }}>
              {search && filteredVideos.length > 0 && filteredVideos[0].category === category ? (
                <Box key={filteredVideos[0].id} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
                        {filteredVideos[0].title}
                      </Typography>
                      {isAdmin && <Tooltip title="Elimina Video">
                        <IconButton onClick={() => handleDeleteVideo(filteredVideos[0])} color="inherit" sx={{ color: 'gray' }}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>}
                    </Box>
                    <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                      <iframe
                        width="100%"
                        height="315"
                        src={`https://www.youtube.com/embed/${filteredVideos[0].id}`}
                        title={filteredVideos[0].title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </Paper>
                  </Box>
                </Box>
              ) : (
                videoLinks[category]?.map((video, index) => (
                  <Box key={video.id} sx={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ flexGrow: 1 }}>
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
                          {video.title}
                        </Typography>
                        {isAdmin && <Tooltip title="Elimina Video">
                          <IconButton onClick={() => handleDeleteVideo(video)} color="inherit" sx={{ color: 'gray' }}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>}
                      </Box>
                      <Paper elevation={3} sx={{ overflow: 'hidden' }}>
                        <iframe
                          width="100%"
                          height="315"
                          src={`https://www.youtube.com/embed/${video.id}`}
                          title={video.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </Paper>
                    </Box>
                    {index < videoLinks[category].length - 1 && <Divider sx={{ margin: '20px 0' }} />}
                  </Box>
                ))
              )}
            </AccordionDetails>
          </Accordion>
        ))
      )}

      {/* Dialog per aggiungere un video */}
      <Dialog open={openVideoDialog} onClose={() => setOpenVideoDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Aggiungi Video in Questa Sezione</DialogTitle>
        <DialogContent>
          <TextField
            label="Titolo"
            value={newVideo.title}
            onChange={(e) => setNewVideo({ ...newVideo, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="URL"
            value={newVideo.id}
            onChange={(e) => setNewVideo({ ...newVideo, id: e.target.value })}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenVideoDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleSaveVideo} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog per aggiungere una nuova categoria */}
      <Dialog open={openCategoryDialog} onClose={() => setOpenCategoryDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Aggiungi Sezione</DialogTitle>
        <DialogContent>
          <TextField
            label="Nome Sezione"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCategoryDialog(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={handleSaveCategory} color="primary">
            Salva
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog di conferma per eliminare una Categoria o un Video*/}
      <Dialog open={openDeleteDialog} onClose={handleCloseDialog} fullWidth maxWidth="xs">
        <DialogTitle>Conferma Eliminazione</DialogTitle>
        <DialogContent>
          {categoryToDelete ? (
            <Typography>Sei sicuro di voler eliminare la sezione "<b>{categoryToDelete}</b>" e tutti i suoi video?</Typography>
          ) : videoToDelete ? (
            <Typography>Sei sicuro di voler eliminare il video "<b>{videoToDelete.title}</b>"?</Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annulla
          </Button>
          <Button
            onClick={categoryToDelete ? confirmDeleteCategory : confirmDeleteVideo}
            color="error"
          >
            Elimina
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar per messaggi */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Container>
  );
};

export default Formazione;
