import React, { useState, useEffect } from 'react';
import { format, startOfMonth, endOfMonth, startOfWeek, endOfWeek, isSameMonth, isSameDay } from 'date-fns';
import { it } from 'date-fns/locale';
import {
  Grid, Button, Typography, Box, Paper, IconButton, Divider, Dialog, DialogTitle, DialogContent,
  CircularProgress, FormControl, Tooltip, TextField, MenuItem,
  Stack, DialogActions, DialogContentText
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from "axios";
import utils from "../../utils";

const AreaPersonale = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [permissions, setPermissions] = useState({});
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loading, setLoading] = useState(true);
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setError] = useState(true);
  const [note, setNote] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [type, setType] = useState("");
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);

  useEffect(() => {
    const startDate = startOfWeek(startOfMonth(selectedDate));
    startDate.setHours(12);
    const endDate = endOfWeek(endOfMonth(selectedDate));
    endDate.setHours(12);

    // Dati di esempio per gennaio 2025
    const examplePermissions = {
      "2025-01-01": {
        "09:00": { type: "Ferie", note: "Vacanza", startTime: "09:00", endTime: "10:00", userId: 1 },
        "10:00": { type: "Permesso", note: "Appuntamento", startTime: "10:00", endTime: "11:00", userId: 2 },
      },
      "2025-01-02": {
        "11:00": { type: "Malattia", note: "Influenza", startTime: "11:00", endTime: "12:00", userId: 3 },
      },
      "2025-01-03": {
        "12:00": { type: "Altro", note: "Riunione", startTime: "12:00", endTime: "13:00", userId: 4 },
      },
    };

    // Carica i dati di esempio solo per gennaio 2025
    if (selectedDate.getMonth() === 0 && selectedDate.getFullYear() === 2025) {
      setPermissions(examplePermissions);
      setLoading(false);
    } else {
      loadPermissions(startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10));
    }
  }, [selectedDate]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const res = await axios.get("/api/me", {
          headers: utils.getAuthHeaders(),
          validateStatus: () => true,
        });
        if (res.status === 200) {
          const { success, me } = res.data;
          if (success) {
            setUserId(me.id);
            setUsername(`${me.nome.charAt(0).toUpperCase()}${me.nome.substring(1).toLowerCase()} ${me.cognome.charAt(0).toUpperCase()}.`);
            setIsAdmin(me.isAdmin);
          }
        }
      } catch (_) { }
    };

    loadUserData();
  }, []);

  const loadPermissions = async (da, a) => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/permissions?da=${da}&a=${a}`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        setPermissions(res.data.permissions);
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  }

  const handlePrevMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setSelectedDate(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setSelectedDate(newDate);
  };

  const openConfirmDialog = (day, timeSlot, isExistingRequest = false) => {
    setSelectedDay(day);
    setSelectedSlot(timeSlot);
    if (isExistingRequest) {
      const permissionDetails = permissions[day] && permissions[day][timeSlot];
      if (permissionDetails) {
        setType(permissionDetails.type);
        setNote(permissionDetails.note);
        setStartTime(permissionDetails.startTime);
        setEndTime(permissionDetails.endTime);
      }
    } else {
      setType("");
      setNote("");
      setStartTime("");
      setEndTime("");
    }
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setSelectedDay(null);
    setSelectedSlot(null);
    setNote("");
    setStartTime("");
    setEndTime("");
    setType("");
  };

  const confirmPermission = () => {
    if (selectedSlot && type && startTime && endTime) {
      handlePermissionRequest(selectedDay, selectedSlot, type, note, startTime, endTime);
    }
    closeConfirmDialog();
  };

  const handlePermissionRequest = async (day, timeSlot, type, note, startTime, endTime) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/requestpermission`,
        { day, timeSlot, type, note, startTime, endTime },
        {
          headers: utils.getAuthHeaders(),
        }
      );
      const { success, error } = res.data;
      if (success) {
        const startDate = startOfWeek(startOfMonth(selectedDate));
        startDate.setHours(12);
        const endDate = endOfWeek(endOfMonth(selectedDate));
        endDate.setHours(12);
        await loadPermissions(startDate.toISOString().substring(0, 10), endDate.toISOString().substring(0, 10));
      } else {
        setError(true);
        setStatusMessage(error);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
      setLoading(false);
    }
  };

  const isSlotBooked = (day, timeSlot) => {
    return permissions[day] && permissions[day][timeSlot];
  };

  const getPermissionColor = (type) => {
    switch (type) {
      case 'Ferie':
        return 'green';
      case 'Permesso':
        return 'blue';
      case 'Malattia':
        return 'red';
      case 'Altro':
        return 'orange';
      default:
        return 'gray';
    }
  };

  const renderSlots = day => {
    const slots = [];
    for (let hour = 9; hour < 18; hour++) {
      const hourString = hour.toString().padStart(2, '0');
      const hourToString = (hour + 1).toString().padStart(2, '0');
      const timeSlot = `${hourString}:00`;
      const booked = isSlotBooked(day, timeSlot);
      const isSelected = selectedSlot === timeSlot;
      const permissionDetails = booked ? permissions[day][timeSlot] : null;
      slots.push(
        <Tooltip key={timeSlot} title={booked ? `${permissionDetails.type} - ${permissionDetails.note} - ${permissionDetails.startTime} - ${permissionDetails.endTime}` : ''} arrow>
          <span>
            <Button
              key={timeSlot}
              variant={booked || isSelected ? "contained" : "outlined"}
              color="primary"
              onClick={() => {
                setSelectedSlot(timeSlot)
                openConfirmDialog(day, timeSlot, booked);
              }}
              style={{
                margin: '4px',
                minWidth: '120px',
                textTransform: 'none',
                backgroundColor: booked ? getPermissionColor(permissionDetails.type) : (isSelected ? '#FEB200' : 'transparent'),
                color: booked ? '#fff' : 'primary',
              }}
            >
              {booked ? (
                <Box display="flex" alignItems="center">
                  <span>User {permissionDetails.userId} - {permissionDetails.type}</span> {/* Modifica qui */}
                  {permissionDetails.type === 'Permesso' && (
                    <span> - {permissionDetails.startTime} - {permissionDetails.endTime}</span>
                  )}
                </Box>
              ) : (
                <Box display="flex" alignItems="center">
                  <span>{username}</span> {/* Aggiungi questa riga */}
                  <span> - {timeSlot} - {hourToString}:00</span>
                </Box>
              )}
            </Button>
          </span>
        </Tooltip>
      );
    }
    return slots;
  };

  const generateCalendar = () => {
    const startDate = startOfWeek(startOfMonth(selectedDate), { weekStartsOn: 1 });
    const endDate = endOfWeek(endOfMonth(selectedDate), { weekStartsOn: 1 });
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, 'd EEE', { locale: it });
        const dayStr = format(day, 'yyyy-MM-dd');
        const dayPermissions = permissions[dayStr];
        days.push(
          <Grid item xs={12} sm key={day} onClick={() => openConfirmDialog(dayStr, null, false)}>
            <Paper
              elevation={3}
              style={{
                padding: '10px',
                minHeight: '120px',
                borderRadius: '8px',
                border: isSameDay(day, new Date()) ? '2px solid #FEB200' : '1px solid #ccc',
                backgroundColor: isSameMonth(day, selectedDate) ? '#fff' : '#f0f0f0',
                marginBottom: '8px',
              }}
            >
              <Typography variant="body2">{formattedDate}</Typography>
              {dayPermissions && (
                <Box display="flex" flexWrap="wrap" justifyContent="left" mt={1}>
                  {Object.keys(dayPermissions).filter(slot => dayPermissions[slot]).map(slot => (
                    <Box
                      key={slot}
                      bgcolor={getPermissionColor(dayPermissions[slot].type)}
                      color="#fff"
                      px={0.5}
                      py={0.25}
                      mx={0.25}
                      mb={0.5}
                      borderRadius={1}
                      fontSize="0.6rem"
                      lineHeight={1}
                      display="flex"
                      alignItems="center"
                    >
                      <span>{username}</span> {/* Aggiungi questa riga */}
                      <span> - {dayPermissions[slot].type}</span> {/* Modifica qui */}
                      {dayPermissions[slot].type === 'Permesso' && (
                        <span> - {dayPermissions[slot].startTime} - {dayPermissions[slot].endTime}</span>
                      )}
                    </Box>
                  ))}
                </Box>
              )}
            </Paper>
          </Grid>
        );
        day.setDate(day.getDate() + 1);
      }
      rows.push(
        <Grid container spacing={2} key={day}>
          {days}
        </Grid>
      );
      days = [];
    }
    return rows;
  };

  const loadPendingRequests = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`/api/pendingrequests`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, requests } = res.data;
      if (success) {
        setPendingRequests(requests);
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const approveRequest = async (requestId) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/approverequest`, { requestId }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        await loadPendingRequests();
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  const rejectRequest = async (requestId) => {
    setLoading(true);
    try {
      const res = await axios.post(`/api/rejectrequest`, { requestId }, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error } = res.data;
      if (success) {
        await loadPendingRequests();
      } else {
        setError(true);
        setStatusMessage(error);
      }
    } catch (error) {
      setError(true);
      setStatusMessage(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isAdmin) {
      loadPendingRequests();
    }
  }, [isAdmin]);

  return (
    <Box p={3} textAlign="center">
      <Typography variant="h4" gutterBottom>Area Personale</Typography>
      <Divider style={{ marginBottom: '20px' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <IconButton onClick={handlePrevMonth}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">{format(selectedDate, 'MMMM yyyy', { locale: it })}</Typography>
        <IconButton onClick={handleNextMonth}>
          <ArrowForwardIcon />
        </IconButton>
      </Box>

      {loading ? (<Stack sx={{ my: 3 }} alignItems="center">
        <CircularProgress disableShrink />
      </Stack>) : (<div />)}
      {statusMessage && (
        <Paper
          variant="outlined"
          sx={{
            p: 2,
            my: 1,
            mx: 2,
            flexGrow: 1,
            backgroundColor: isError ? "#e57373" : "#81c784",
            borderColor: isError ? "#d32f2f" : "#388e3c",
            color: "black",
          }}
          role="alert"
        >
          {statusMessage}
        </Paper>
      )}

      <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
        {generateCalendar()}
      </div>

      {isAdmin && (
        <div>
          <Typography variant="h5" gutterBottom>Richieste in Sospeso</Typography>
          {pendingRequests.map(request => (
            <Paper key={request.id} elevation={3} style={{ padding: '10px', marginBottom: '8px' }}>
              <Typography variant="body1">{request.type} - {request.note} - {request.startTime} - {request.endTime}</Typography>
              <Button onClick={() => approveRequest(request.id)} color="primary" variant="contained">Approva</Button>
              <Button onClick={() => rejectRequest(request.id)} color="secondary" variant="contained">Rifiuta</Button>
            </Paper>
          ))}
        </div>
      )}

      {/* Dialogo di conferma per la richiesta di permesso */}
      <Dialog open={confirmDialogOpen} onClose={closeConfirmDialog}>
        <DialogTitle>Richiesta Permesso</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedDay && format(new Date(`${selectedDay} 12:00:00`), 'dd MMMM yyyy', { locale: it })}.
          </DialogContentText>
          <Box mb={2} />
          <FormControl sx={{ width: '100%', mb: 2 }}>
            <TextField
              select
              label="Tipo di Permesso"
              value={type}
              onChange={(e) => setType(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            >
              <MenuItem value="Ferie">Ferie</MenuItem>
              <MenuItem value="Permesso">Permesso</MenuItem>
              <MenuItem value="Malattia">Malattia</MenuItem>
              <MenuItem value="Altro">Altro</MenuItem>
            </TextField>
          </FormControl>
          <FormControl sx={{ width: '100%', mb: 2 }}>
            <TextField
              label="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              multiline
              rows={5}
            />
          </FormControl>
          <FormControl sx={{ width: '100%', mb: 2 }}>
            <TextField
              label="Orario Inizio"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </FormControl>
          <FormControl sx={{ width: '100%', mb: 2 }}>
            <TextField
              label="Orario Fine"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDialog} color="primary">
            Annulla
          </Button>
          <Button onClick={confirmPermission} color="primary" variant="contained">
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AreaPersonale;
