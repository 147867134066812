import React, { useState, useEffect } from 'react';
import {
  Container, Typography, TextField, Button, Grid, Paper, FormControl, InputLabel, Select, MenuItem,
  Snackbar, Alert, Divider, IconButton, Box, Stack, CircularProgress, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Tooltip, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import Chip from '@mui/material/Chip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import axios from "axios";
import utils from "../../utils";

const OpNews = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [newsList, setNewsList] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const [files, setFiles] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const res = await axios.get(`/api/newsclienti`, {
        headers: utils.getAuthHeaders(),
      });
      const { success, error, news } = res.data;
      if (success) {
        setNewsList(news);
      } else {
        setError(error);
      }
    } catch (error) {
      setError("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setIsLoading(false);
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!title || !content || !category) {
      setError('Tutti i campi sono obbligatori.');
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify({ title, content, category }));
      files.forEach((file) => formData.append('files', file));
      const res = await axios.post("/api/createnewsclienti", formData, {
        headers: {
          ...utils.getAuthHeaders(),
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, error } = res.data;
      if (success) {
        return window.location.reload();
      } else {
        setError(error);
      }
    } catch (error) {
      setError(
        "Si è verificato un errore imprevisto sul nostro server."
      );
      console.log(error);
    }
    setIsLoading(false);
  };

  // Funzione per eliminare le news
  const handleDelete = (id) => {
    setNewsToDelete(id);
    setDeleteDialogOpen(true);
  };

  // Funzione confirmDelete per confermare l'eliminazione
  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      const res = await axios.post("/api/deletenewsclienti", { id: newsToDelete }, {
        headers: {
          ...utils.getAuthHeaders(),
        },
      });
      const { success, error } = res.data;
      if (success) {
        setNewsList(newsList.filter(news => news.id !== newsToDelete));
        setSuccess(true);
      } else {
        setError(error);
      }
    } catch (error) {
      setError("Si è verificato un errore imprevisto sul nostro server.");
      console.log(error);
    }
    setIsLoading(false);
    setDeleteDialogOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Funzione per gestire la stampa
  const handlePrint = (news) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
        <html>
          <head>
            <title>Print News</title>
            <style>
              body { font-family: Arial, sans-serif; }
              .news-content { white-space: pre-line; }
              .news-header { text-align: center; margin-bottom: 20px; }
              .news-title { font-size: 1.5em; font-weight: bold; }
              .news-date { font-size: 1em; color: gray; }
              .header-image {
              width: 30%; /* Ridimensiona l'immagine */
              max-width: 300px; /* Limita la larghezza massima */
              margin: -50px auto 0px; /* Sposta l'immagine più in alto e centra */
              display: block;
            }
            </style>
          </head>
          <body>
          <img src="/CartaIntestata.svg" alt="Carta Intestata" class="header-image" />
            <div class="news-header">
              <div class="news-title">${news.title}</div>
              <div class="news-date">${new Date(news.date).toLocaleDateString()}</div>
            </div>
            <div class="news-content">${news.content}</div>
          </body>
        </html>
      `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <Container maxWidth="xl" style={{ padding: '10px' }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        align="center"
        sx={{ margin: '20px', color: '#000000' }}
      >
        Inserisci Notizia
      </Typography>

      <Paper elevation={3} sx={{ padding: 2 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Titolo"
                variant="outlined"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Inserisci il titolo della notizia"
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Categoria</InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="Categoria"
                  required
                >
                  <MenuItem value="Aggiornamenti">Aggiornamenti Software</MenuItem>
                  <MenuItem value="Notizie">Notizie</MenuItem>
                  <MenuItem value="Circolari">Circolari</MenuItem>
                  <MenuItem value="Eventi">Eventi</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contenuto"
                variant="outlined"
                multiline
                rows={6}
                fullWidth
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Inserisci il contenuto della notizia"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                type="file"
                multiple
                onChange={(e) => setFiles([...e.target.files])}
                style={{ display: 'none' }}
                id="file-input"
              />
              <label htmlFor="file-input">
                <Button variant="outlined" component="span">
                  Allega File
                </Button>
              </label>
              {files.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  {files.map((file, index) => (
                    <Chip
                      key={index}
                      label={file.name}
                      onDelete={() => {
                        const newFiles = [...files];
                        newFiles.splice(index, 1);
                        setFiles(newFiles);
                      }}
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button type="submit" variant="contained" color="primary">
                Inserisci
              </Button>
            </Grid>
          </Grid>
        </form>

        <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
          <Alert onClose={() => setSuccess(false)} severity="success">
            Notizia inserita con successo!
          </Alert>
        </Snackbar>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </Paper>
      <Divider sx={{ margin: '20px 0' }} />

      <Typography variant="h5" component="h2" gutterBottom align="center">
        Storico delle Notizie
      </Typography>
      {loading ? (
        <Stack sx={{ my: 5 }} alignItems="center">
          <CircularProgress disableShrink />
        </Stack>
      ) : (
        <Paper elevation={3} sx={{ padding: 2 }}>
          {newsList.length === 0 ? (
            <Typography align="center">Nessuna notizia inserita.</Typography>
          ) : (
            newsList.map((news) => (
              <Accordion
                key={news.id}
                expanded={expanded === `news-${news.id}`}
                onChange={handleChange(`news-${news.id}`)}
                sx={{ marginBottom: '20px', borderRadius: '8px', boxShadow: 3 }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`news-${news.id}-content`}
                  id={`news-${news.id}-header`}
                  sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ddd' }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {news.title}
                      </Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        Categoria: {news.category} | Data: {new Date(news.date).toLocaleDateString()}
                      </Typography>
                    </Box>
                    <PrintOutlinedIcon
                      sx={{ marginLeft: 'auto', cursor: 'pointer', color: 'gray' }}
                      onClick={() => handlePrint(news)}
                    />
                    <Tooltip title="Elimina Notizia">
                      <IconButton onClick={() => handleDelete(news.id)}>
                        <DeleteIcon color="gray" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#fafafa' }}>
                  <Typography variant="body1" sx={{ marginTop: 1, whiteSpace: 'pre-line' }}>
                    {news.content}
                  </Typography>
                  {news.files && news.files.length > 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Divider sx={{ mb: 1 }} />
                      <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>File Allegati:</Typography>
                      {news.files.map((file, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography variant="body2">{file.name}</Typography>
                          {file.name.endsWith('.pdf') && (
                            <Tooltip title="Anteprima">
                              <IconButton onClick={() => utils.opOpenPdfInNewTab(file.id)}>
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Scarica">
                            <IconButton onClick={() => utils.opDownload(file.id)} download>
                              <DownloadIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ))}
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Paper>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Conferma Eliminazione</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sei sicuro di voler eliminare questa notizia? Questa azione non può essere annullata.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={confirmDelete} color="error" variant="contained">
            Elimina
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OpNews;